export default {
  people: [
    {
      names: ["grinning"],
      surrogates: "😀",
    },
    {
      names: ["grimacing"],
      surrogates: "😬",
    },
    {
      names: ["grin"],
      surrogates: "😁",
    },
    {
      names: ["joy"],
      surrogates: "😂",
    },
    {
      names: ["smiley"],
      surrogates: "😃",
    },
    {
      names: ["smile"],
      surrogates: "😄",
    },
    {
      names: ["sweat_smile"],
      surrogates: "😅",
    },
    {
      names: ["laughing", "satisfied"],
      surrogates: "😆",
    },
    {
      names: ["innocent"],
      surrogates: "😇",
    },
    {
      names: ["wink"],
      surrogates: "😉",
    },
    {
      names: ["blush"],
      surrogates: "😊",
    },
    {
      names: ["slight_smile", "slightly_smiling_face"],
      surrogates: "🙂",
    },
    {
      names: ["upside_down", "upside_down_face"],
      surrogates: "🙃",
    },
    {
      names: ["relaxed"],
      surrogates: "☺",
    },
    {
      names: ["yum"],
      surrogates: "😋",
    },
    {
      names: ["relieved"],
      surrogates: "😌",
    },
    {
      names: ["heart_eyes"],
      surrogates: "😍",
    },
    {
      names: ["kissing_heart"],
      surrogates: "😘",
    },
    {
      names: ["kissing"],
      surrogates: "😗",
    },
    {
      names: ["kissing_smiling_eyes"],
      surrogates: "😙",
    },
    {
      names: ["kissing_closed_eyes"],
      surrogates: "😚",
    },
    {
      names: ["stuck_out_tongue_winking_eye"],
      surrogates: "😜",
    },
    {
      names: ["stuck_out_tongue_closed_eyes"],
      surrogates: "😝",
    },
    {
      names: ["stuck_out_tongue"],
      surrogates: "😛",
    },
    {
      names: ["money_mouth", "money_mouth_face"],
      surrogates: "🤑",
    },
    {
      names: ["nerd", "nerd_face"],
      surrogates: "🤓",
    },
    {
      names: ["sunglasses"],
      surrogates: "😎",
    },
    {
      names: ["hugging", "hugging_face"],
      surrogates: "🤗",
    },
    {
      names: ["smirk"],
      surrogates: "😏",
    },
    {
      names: ["no_mouth"],
      surrogates: "😶",
    },
    {
      names: ["neutral_face"],
      surrogates: "😐",
    },
    {
      names: ["expressionless"],
      surrogates: "😑",
    },
    {
      names: ["unamused"],
      surrogates: "😒",
    },
    {
      names: ["rolling_eyes", "face_with_rolling_eyes"],
      surrogates: "🙄",
    },
    {
      names: ["thinking", "thinking_face"],
      surrogates: "🤔",
    },
    {
      names: ["flushed"],
      surrogates: "😳",
    },
    {
      names: ["disappointed"],
      surrogates: "😞",
    },
    {
      names: ["worried"],
      surrogates: "😟",
    },
    {
      names: ["angry"],
      surrogates: "😠",
    },
    {
      names: ["rage"],
      surrogates: "😡",
    },
    {
      names: ["pensive"],
      surrogates: "😔",
    },
    {
      names: ["confused"],
      surrogates: "😕",
    },
    {
      names: ["slight_frown", "slightly_frowning_face"],
      surrogates: "🙁",
    },
    {
      names: ["frowning2", "white_frowning_face"],
      surrogates: "☹",
    },
    {
      names: ["persevere"],
      surrogates: "😣",
    },
    {
      names: ["confounded"],
      surrogates: "😖",
    },
    {
      names: ["tired_face"],
      surrogates: "😫",
    },
    {
      names: ["weary"],
      surrogates: "😩",
    },
    {
      names: ["triumph"],
      surrogates: "😤",
    },
    {
      names: ["open_mouth"],
      surrogates: "😮",
    },
    {
      names: ["scream"],
      surrogates: "😱",
    },
    {
      names: ["fearful"],
      surrogates: "😨",
    },
    {
      names: ["cold_sweat"],
      surrogates: "😰",
    },
    {
      names: ["hushed"],
      surrogates: "😯",
    },
    {
      names: ["frowning"],
      surrogates: "😦",
    },
    {
      names: ["anguished"],
      surrogates: "😧",
    },
    {
      names: ["cry"],
      surrogates: "😢",
    },
    {
      names: ["disappointed_relieved"],
      surrogates: "😥",
    },
    {
      names: ["sleepy"],
      surrogates: "😪",
    },
    {
      names: ["sweat"],
      surrogates: "😓",
    },
    {
      names: ["sob"],
      surrogates: "😭",
    },
    {
      names: ["dizzy_face"],
      surrogates: "😵",
    },
    {
      names: ["astonished"],
      surrogates: "😲",
    },
    {
      names: ["zipper_mouth", "zipper_mouth_face"],
      surrogates: "🤐",
    },
    {
      names: ["mask"],
      surrogates: "😷",
    },
    {
      names: ["thermometer_face", "face_with_thermometer"],
      surrogates: "🤒",
    },
    {
      names: ["head_bandage", "face_with_head_bandage"],
      surrogates: "🤕",
    },
    {
      names: ["sleeping"],
      surrogates: "😴",
    },
    {
      names: ["zzz"],
      surrogates: "💤",
    },
    {
      names: ["poop", "shit", "hankey", "poo"],
      surrogates: "💩",
    },
    {
      names: ["smiling_imp"],
      surrogates: "😈",
    },
    {
      names: ["imp"],
      surrogates: "👿",
    },
    {
      names: ["japanese_ogre"],
      surrogates: "👹",
    },
    {
      names: ["japanese_goblin"],
      surrogates: "👺",
    },
    {
      names: ["skull", "skeleton"],
      surrogates: "💀",
    },
    {
      names: ["ghost"],
      surrogates: "👻",
    },
    {
      names: ["alien"],
      surrogates: "👽",
    },
    {
      names: ["robot", "robot_face"],
      surrogates: "🤖",
    },
    {
      names: ["smiley_cat"],
      surrogates: "😺",
    },
    {
      names: ["smile_cat"],
      surrogates: "😸",
    },
    {
      names: ["joy_cat"],
      surrogates: "😹",
    },
    {
      names: ["heart_eyes_cat"],
      surrogates: "😻",
    },
    {
      names: ["smirk_cat"],
      surrogates: "😼",
    },
    {
      names: ["kissing_cat"],
      surrogates: "😽",
    },
    {
      names: ["scream_cat"],
      surrogates: "🙀",
    },
    {
      names: ["crying_cat_face"],
      surrogates: "😿",
    },
    {
      names: ["pouting_cat"],
      surrogates: "😾",
    },
    {
      names: ["raised_hands"],
      surrogates: "🙌",
      hasDiversity: !0,
    },
    {
      names: ["clap"],
      surrogates: "👏",
      hasDiversity: !0,
    },
    {
      names: ["wave"],
      surrogates: "👋",
      hasDiversity: !0,
    },
    {
      names: ["thumbsup", "+1", "thumbup"],
      surrogates: "👍",
      hasDiversity: !0,
    },
    {
      names: ["thumbsdown", "-1", "thumbdown"],
      surrogates: "👎",
      hasDiversity: !0,
    },
    {
      names: ["punch"],
      surrogates: "👊",
      hasDiversity: !0,
    },
    {
      names: ["fist"],
      surrogates: "✊",
      hasDiversity: !0,
    },
    {
      names: ["v"],
      surrogates: "✌",
      hasDiversity: !0,
    },
    {
      names: ["ok_hand"],
      surrogates: "👌",
      hasDiversity: !0,
    },
    {
      names: ["raised_hand"],
      surrogates: "✋",
      hasDiversity: !0,
    },
    {
      names: ["open_hands"],
      surrogates: "👐",
      hasDiversity: !0,
    },
    {
      names: ["muscle"],
      surrogates: "💪",
      hasDiversity: !0,
    },
    {
      names: ["pray"],
      surrogates: "🙏",
      hasDiversity: !0,
    },
    {
      names: ["point_up"],
      surrogates: "☝",
      hasDiversity: !0,
    },
    {
      names: ["point_up_2"],
      surrogates: "👆",
      hasDiversity: !0,
    },
    {
      names: ["point_down"],
      surrogates: "👇",
      hasDiversity: !0,
    },
    {
      names: ["point_left"],
      surrogates: "👈",
      hasDiversity: !0,
    },
    {
      names: ["point_right"],
      surrogates: "👉",
      hasDiversity: !0,
    },
    {
      names: ["middle_finger", "reversed_hand_with_middle_finger_extended"],
      surrogates: "🖕",
      hasDiversity: !0,
    },
    {
      names: ["hand_splayed", "raised_hand_with_fingers_splayed"],
      surrogates: "🖐",
      hasDiversity: !0,
    },
    {
      names: ["metal", "sign_of_the_horns"],
      surrogates: "🤘",
      hasDiversity: !0,
    },
    {
      names: ["vulcan", "raised_hand_with_part_between_middle_and_ring_fingers"],
      surrogates: "🖖",
      hasDiversity: !0,
    },
    {
      names: ["writing_hand"],
      surrogates: "✍",
      hasDiversity: !0,
    },
    {
      names: ["nail_care"],
      surrogates: "💅",
      hasDiversity: !0,
    },
    {
      names: ["lips"],
      surrogates: "👄",
    },
    {
      names: ["tongue"],
      surrogates: "👅",
    },
    {
      names: ["ear"],
      surrogates: "👂",
      hasDiversity: !0,
    },
    {
      names: ["nose"],
      surrogates: "👃",
      hasDiversity: !0,
    },
    {
      names: ["eye"],
      surrogates: "👁",
    },
    {
      names: ["eyes"],
      surrogates: "👀",
    },
    {
      names: ["bust_in_silhouette"],
      surrogates: "👤",
    },
    {
      names: ["busts_in_silhouette"],
      surrogates: "👥",
    },
    {
      names: ["speaking_head", "speaking_head_in_silhouette"],
      surrogates: "🗣",
    },
    {
      names: ["baby"],
      surrogates: "👶",
      hasDiversity: !0,
    },
    {
      names: ["boy"],
      surrogates: "👦",
      hasDiversity: !0,
    },
    {
      names: ["girl"],
      surrogates: "👧",
      hasDiversity: !0,
    },
    {
      names: ["man"],
      surrogates: "👨",
      hasDiversity: !0,
    },
    {
      names: ["woman"],
      surrogates: "👩",
      hasDiversity: !0,
    },
    {
      names: ["person_with_blond_hair"],
      surrogates: "👱",
      hasDiversity: !0,
    },
    {
      names: ["older_man"],
      surrogates: "👴",
      hasDiversity: !0,
    },
    {
      names: ["older_woman", "grandma"],
      surrogates: "👵",
      hasDiversity: !0,
    },
    {
      names: ["man_with_gua_pi_mao"],
      surrogates: "👲",
      hasDiversity: !0,
    },
    {
      names: ["man_with_turban"],
      surrogates: "👳",
      hasDiversity: !0,
    },
    {
      names: ["cop"],
      surrogates: "👮",
      hasDiversity: !0,
    },
    {
      names: ["construction_worker"],
      surrogates: "👷",
      hasDiversity: !0,
    },
    {
      names: ["guardsman"],
      surrogates: "💂",
      hasDiversity: !0,
    },
    {
      names: ["spy", "sleuth_or_spy"],
      surrogates: "🕵",
      hasDiversity: !0,
    },
    {
      names: ["santa"],
      surrogates: "🎅",
      hasDiversity: !0,
    },
    {
      names: ["angel"],
      surrogates: "👼",
      hasDiversity: !0,
    },
    {
      names: ["princess"],
      surrogates: "👸",
      hasDiversity: !0,
    },
    {
      names: ["bride_with_veil"],
      surrogates: "👰",
      hasDiversity: !0,
    },
    {
      names: ["walking"],
      surrogates: "🚶",
      hasDiversity: !0,
    },
    {
      names: ["runner"],
      surrogates: "🏃",
      hasDiversity: !0,
    },
    {
      names: ["dancer"],
      surrogates: "💃",
      hasDiversity: !0,
    },
    {
      names: ["dancers"],
      surrogates: "👯",
      hasDiversity: !0,
    },
    {
      names: ["couple"],
      surrogates: "👫",
      hasDiversity: !0,
    },
    {
      names: ["two_men_holding_hands"],
      surrogates: "👬",
      hasDiversity: !0,
    },
    {
      names: ["two_women_holding_hands"],
      surrogates: "👭",
      hasDiversity: !0,
    },
    {
      names: ["bow"],
      surrogates: "🙇",
      hasDiversity: !0,
    },
    {
      names: ["information_desk_person"],
      surrogates: "💁",
      hasDiversity: !0,
    },
    {
      names: ["no_good"],
      surrogates: "🙅",
      hasDiversity: !0,
    },
    {
      names: ["ok_woman"],
      surrogates: "🙆",
      hasDiversity: !0,
    },
    {
      names: ["raising_hand"],
      surrogates: "🙋",
      hasDiversity: !0,
    },
    {
      names: ["person_with_pouting_face"],
      surrogates: "🙎",
      hasDiversity: !0,
    },
    {
      names: ["person_frowning"],
      surrogates: "🙍",
      hasDiversity: !0,
    },
    {
      names: ["haircut"],
      surrogates: "💇",
      hasDiversity: !0,
    },
    {
      names: ["massage"],
      surrogates: "💆",
      hasDiversity: !0,
    },
    {
      names: ["couple_with_heart"],
      surrogates: "💑",
    },
    {
      names: ["couple_ww", "couple_with_heart_ww"],
      surrogates: "👩‍❤️‍👩",
    },
    {
      names: ["couple_mm", "couple_with_heart_mm"],
      surrogates: "👨‍❤️‍👨",
    },
    {
      names: ["couplekiss"],
      surrogates: "💏",
    },
    {
      names: ["kiss_ww", "couplekiss_ww"],
      surrogates: "👩‍❤️‍💋‍👩",
    },
    {
      names: ["kiss_mm", "couplekiss_mm"],
      surrogates: "👨‍❤️‍💋‍👨",
    },
    {
      names: ["family"],
      surrogates: "👪",
      hasDiversity: !0,
    },
    {
      names: ["family_mwg"],
      surrogates: "👨‍👩‍👧",
    },
    {
      names: ["family_mwgb"],
      surrogates: "👨‍👩‍👧‍👦",
    },
    {
      names: ["family_mwbb"],
      surrogates: "👨‍👩‍👦‍👦",
    },
    {
      names: ["family_mwgg"],
      surrogates: "👨‍👩‍👧‍👧",
    },
    {
      names: ["family_wwb"],
      surrogates: "👩‍👩‍👦",
    },
    {
      names: ["family_wwg"],
      surrogates: "👩‍👩‍👧",
    },
    {
      names: ["family_wwgb"],
      surrogates: "👩‍👩‍👧‍👦",
    },
    {
      names: ["family_wwbb"],
      surrogates: "👩‍👩‍👦‍👦",
    },
    {
      names: ["family_wwgg"],
      surrogates: "👩‍👩‍👧‍👧",
    },
    {
      names: ["family_mmb"],
      surrogates: "👨‍👨‍👦",
    },
    {
      names: ["family_mmg"],
      surrogates: "👨‍👨‍👧",
    },
    {
      names: ["family_mmgb"],
      surrogates: "👨‍👨‍👧‍👦",
    },
    {
      names: ["family_mmbb"],
      surrogates: "👨‍👨‍👦‍👦",
    },
    {
      names: ["family_mmgg"],
      surrogates: "👨‍👨‍👧‍👧",
    },
    {
      names: ["womans_clothes"],
      surrogates: "👚",
    },
    {
      names: ["shirt"],
      surrogates: "👕",
    },
    {
      names: ["jeans"],
      surrogates: "👖",
    },
    {
      names: ["necktie"],
      surrogates: "👔",
    },
    {
      names: ["dress"],
      surrogates: "👗",
    },
    {
      names: ["bikini"],
      surrogates: "👙",
    },
    {
      names: ["kimono"],
      surrogates: "👘",
    },
    {
      names: ["lipstick"],
      surrogates: "💄",
    },
    {
      names: ["kiss"],
      surrogates: "💋",
    },
    {
      names: ["footprints"],
      surrogates: "👣",
    },
    {
      names: ["high_heel"],
      surrogates: "👠",
    },
    {
      names: ["sandal"],
      surrogates: "👡",
    },
    {
      names: ["boot"],
      surrogates: "👢",
    },
    {
      names: ["mans_shoe"],
      surrogates: "👞",
    },
    {
      names: ["athletic_shoe"],
      surrogates: "👟",
    },
    {
      names: ["womans_hat"],
      surrogates: "👒",
    },
    {
      names: ["tophat"],
      surrogates: "🎩",
    },
    {
      names: ["helmet_with_cross", "helmet_with_white_cross"],
      surrogates: "⛑",
    },
    {
      names: ["mortar_board"],
      surrogates: "🎓",
    },
    {
      names: ["crown"],
      surrogates: "👑",
    },
    {
      names: ["school_satchel"],
      surrogates: "🎒",
    },
    {
      names: ["pouch"],
      surrogates: "👝",
    },
    {
      names: ["purse"],
      surrogates: "👛",
    },
    {
      names: ["handbag"],
      surrogates: "👜",
    },
    {
      names: ["briefcase"],
      surrogates: "💼",
    },
    {
      names: ["eyeglasses"],
      surrogates: "👓",
    },
    {
      names: ["dark_sunglasses"],
      surrogates: "🕶",
    },
    {
      names: ["ring"],
      surrogates: "💍",
    },
    {
      names: ["closed_umbrella"],
      surrogates: "🌂",
    },
    {
      names: ["cowboy", "face_with_cowboy_hat"],
      surrogates: "🤠",
    },
    {
      names: ["clown", "clown_face"],
      surrogates: "🤡",
    },
    {
      names: ["nauseated_face", "sick"],
      surrogates: "🤢",
    },
    {
      names: ["rofl", "rolling_on_the_floor_laughing"],
      surrogates: "🤣",
    },
    {
      names: ["drooling_face", "drool"],
      surrogates: "🤤",
    },
    {
      names: ["lying_face", "liar"],
      surrogates: "🤥",
    },
    {
      names: ["sneezing_face", "sneeze"],
      surrogates: "🤧",
    },
    {
      names: ["prince"],
      surrogates: "🤴",
      hasDiversity: !0,
    },
    {
      names: ["man_in_tuxedo"],
      surrogates: "🤵",
      hasDiversity: !0,
    },
    {
      names: ["mrs_claus", "mother_christmas"],
      surrogates: "🤶",
      hasDiversity: !0,
    },
    {
      names: ["face_palm", "facepalm"],
      surrogates: "🤦",
      hasDiversity: !0,
    },
    {
      names: ["shrug"],
      surrogates: "🤷",
      hasDiversity: !0,
    },
    {
      names: ["pregnant_woman", "expecting_woman"],
      surrogates: "🤰",
      hasDiversity: !0,
    },
    {
      names: ["selfie"],
      surrogates: "🤳",
      hasDiversity: !0,
    },
    {
      names: ["man_dancing", "male_dancer"],
      surrogates: "🕺",
      hasDiversity: !0,
    },
    {
      names: ["call_me", "call_me_hand"],
      surrogates: "🤙",
      hasDiversity: !0,
    },
    {
      names: ["raised_back_of_hand", "back_of_hand"],
      surrogates: "🤚",
      hasDiversity: !0,
    },
    {
      names: ["left_facing_fist", "left_fist"],
      surrogates: "🤛",
      hasDiversity: !0,
    },
    {
      names: ["right_facing_fist", "right_fist"],
      surrogates: "🤜",
      hasDiversity: !0,
    },
    {
      names: ["handshake", "shaking_hands"],
      surrogates: "🤝",
      hasDiversity: !0,
    },
    {
      names: ["fingers_crossed", "hand_with_index_and_middle_finger_crossed"],
      surrogates: "🤞",
      hasDiversity: !0,
    },
  ],
  nature: [
    {
      names: ["dog"],
      surrogates: "🐶",
    },
    {
      names: ["cat"],
      surrogates: "🐱",
    },
    {
      names: ["mouse"],
      surrogates: "🐭",
    },
    {
      names: ["hamster"],
      surrogates: "🐹",
    },
    {
      names: ["rabbit"],
      surrogates: "🐰",
    },
    {
      names: ["bear"],
      surrogates: "🐻",
    },
    {
      names: ["panda_face"],
      surrogates: "🐼",
    },
    {
      names: ["koala"],
      surrogates: "🐨",
    },
    {
      names: ["tiger"],
      surrogates: "🐯",
    },
    {
      names: ["lion_face", "lion"],
      surrogates: "🦁",
    },
    {
      names: ["cow"],
      surrogates: "🐮",
    },
    {
      names: ["pig"],
      surrogates: "🐷",
    },
    {
      names: ["pig_nose"],
      surrogates: "🐽",
    },
    {
      names: ["frog"],
      surrogates: "🐸",
    },
    {
      names: ["octopus"],
      surrogates: "🐙",
    },
    {
      names: ["monkey_face"],
      surrogates: "🐵",
    },
    {
      names: ["see_no_evil"],
      surrogates: "🙈",
    },
    {
      names: ["hear_no_evil"],
      surrogates: "🙉",
    },
    {
      names: ["speak_no_evil"],
      surrogates: "🙊",
    },
    {
      names: ["monkey"],
      surrogates: "🐒",
    },
    {
      names: ["chicken"],
      surrogates: "🐔",
    },
    {
      names: ["penguin"],
      surrogates: "🐧",
    },
    {
      names: ["bird"],
      surrogates: "🐦",
    },
    {
      names: ["baby_chick"],
      surrogates: "🐤",
    },
    {
      names: ["hatching_chick"],
      surrogates: "🐣",
    },
    {
      names: ["hatched_chick"],
      surrogates: "🐥",
    },
    {
      names: ["wolf"],
      surrogates: "🐺",
    },
    {
      names: ["boar"],
      surrogates: "🐗",
    },
    {
      names: ["horse"],
      surrogates: "🐴",
    },
    {
      names: ["unicorn", "unicorn_face"],
      surrogates: "🦄",
    },
    {
      names: ["bee"],
      surrogates: "🐝",
    },
    {
      names: ["bug"],
      surrogates: "🐛",
    },
    {
      names: ["snail"],
      surrogates: "🐌",
    },
    {
      names: ["beetle"],
      surrogates: "🐞",
    },
    {
      names: ["ant"],
      surrogates: "🐜",
    },
    {
      names: ["spider"],
      surrogates: "🕷",
    },
    {
      names: ["scorpion"],
      surrogates: "🦂",
    },
    {
      names: ["crab"],
      surrogates: "🦀",
    },
    {
      names: ["snake"],
      surrogates: "🐍",
    },
    {
      names: ["turtle"],
      surrogates: "🐢",
    },
    {
      names: ["tropical_fish"],
      surrogates: "🐠",
    },
    {
      names: ["fish"],
      surrogates: "🐟",
    },
    {
      names: ["blowfish"],
      surrogates: "🐡",
    },
    {
      names: ["dolphin"],
      surrogates: "🐬",
    },
    {
      names: ["whale"],
      surrogates: "🐳",
    },
    {
      names: ["whale2"],
      surrogates: "🐋",
    },
    {
      names: ["crocodile"],
      surrogates: "🐊",
    },
    {
      names: ["leopard"],
      surrogates: "🐆",
    },
    {
      names: ["tiger2"],
      surrogates: "🐅",
    },
    {
      names: ["water_buffalo"],
      surrogates: "🐃",
    },
    {
      names: ["ox"],
      surrogates: "🐂",
    },
    {
      names: ["cow2"],
      surrogates: "🐄",
    },
    {
      names: ["dromedary_camel"],
      surrogates: "🐪",
    },
    {
      names: ["camel"],
      surrogates: "🐫",
    },
    {
      names: ["elephant"],
      surrogates: "🐘",
    },
    {
      names: ["goat"],
      surrogates: "🐐",
    },
    {
      names: ["ram"],
      surrogates: "🐏",
    },
    {
      names: ["sheep"],
      surrogates: "🐑",
    },
    {
      names: ["racehorse"],
      surrogates: "🐎",
    },
    {
      names: ["pig2"],
      surrogates: "🐖",
    },
    {
      names: ["rat"],
      surrogates: "🐀",
    },
    {
      names: ["mouse2"],
      surrogates: "🐁",
    },
    {
      names: ["rooster"],
      surrogates: "🐓",
    },
    {
      names: ["turkey"],
      surrogates: "🦃",
    },
    {
      names: ["dove", "dove_of_peace"],
      surrogates: "🕊",
    },
    {
      names: ["dog2"],
      surrogates: "🐕",
    },
    {
      names: ["poodle"],
      surrogates: "🐩",
    },
    {
      names: ["cat2"],
      surrogates: "🐈",
    },
    {
      names: ["rabbit2"],
      surrogates: "🐇",
    },
    {
      names: ["chipmunk"],
      surrogates: "🐿",
    },
    {
      names: ["feet", "paw_prints"],
      surrogates: "🐾",
    },
    {
      names: ["dragon"],
      surrogates: "🐉",
    },
    {
      names: ["dragon_face"],
      surrogates: "🐲",
    },
    {
      names: ["cactus"],
      surrogates: "🌵",
    },
    {
      names: ["christmas_tree"],
      surrogates: "🎄",
    },
    {
      names: ["evergreen_tree"],
      surrogates: "🌲",
    },
    {
      names: ["deciduous_tree"],
      surrogates: "🌳",
    },
    {
      names: ["palm_tree"],
      surrogates: "🌴",
    },
    {
      names: ["seedling"],
      surrogates: "🌱",
    },
    {
      names: ["herb"],
      surrogates: "🌿",
    },
    {
      names: ["shamrock"],
      surrogates: "☘",
    },
    {
      names: ["four_leaf_clover"],
      surrogates: "🍀",
    },
    {
      names: ["bamboo"],
      surrogates: "🎍",
    },
    {
      names: ["tanabata_tree"],
      surrogates: "🎋",
    },
    {
      names: ["leaves"],
      surrogates: "🍃",
    },
    {
      names: ["fallen_leaf"],
      surrogates: "🍂",
    },
    {
      names: ["maple_leaf"],
      surrogates: "🍁",
    },
    {
      names: ["ear_of_rice"],
      surrogates: "🌾",
    },
    {
      names: ["hibiscus"],
      surrogates: "🌺",
    },
    {
      names: ["sunflower"],
      surrogates: "🌻",
    },
    {
      names: ["rose"],
      surrogates: "🌹",
    },
    {
      names: ["tulip"],
      surrogates: "🌷",
    },
    {
      names: ["blossom"],
      surrogates: "🌼",
    },
    {
      names: ["cherry_blossom"],
      surrogates: "🌸",
    },
    {
      names: ["bouquet"],
      surrogates: "💐",
    },
    {
      names: ["mushroom"],
      surrogates: "🍄",
    },
    {
      names: ["chestnut"],
      surrogates: "🌰",
    },
    {
      names: ["jack_o_lantern"],
      surrogates: "🎃",
    },
    {
      names: ["shell"],
      surrogates: "🐚",
    },
    {
      names: ["spider_web"],
      surrogates: "🕸",
    },
    {
      names: ["earth_americas"],
      surrogates: "🌎",
    },
    {
      names: ["earth_africa"],
      surrogates: "🌍",
    },
    {
      names: ["earth_asia"],
      surrogates: "🌏",
    },
    {
      names: ["full_moon"],
      surrogates: "🌕",
    },
    {
      names: ["waning_gibbous_moon"],
      surrogates: "🌖",
    },
    {
      names: ["last_quarter_moon"],
      surrogates: "🌗",
    },
    {
      names: ["waning_crescent_moon"],
      surrogates: "🌘",
    },
    {
      names: ["new_moon"],
      surrogates: "🌑",
    },
    {
      names: ["waxing_crescent_moon"],
      surrogates: "🌒",
    },
    {
      names: ["first_quarter_moon"],
      surrogates: "🌓",
    },
    {
      names: ["waxing_gibbous_moon"],
      surrogates: "🌔",
    },
    {
      names: ["new_moon_with_face"],
      surrogates: "🌚",
    },
    {
      names: ["full_moon_with_face"],
      surrogates: "🌝",
    },
    {
      names: ["first_quarter_moon_with_face"],
      surrogates: "🌛",
    },
    {
      names: ["last_quarter_moon_with_face"],
      surrogates: "🌜",
    },
    {
      names: ["sun_with_face"],
      surrogates: "🌞",
    },
    {
      names: ["crescent_moon"],
      surrogates: "🌙",
    },
    {
      names: ["star"],
      surrogates: "⭐",
    },
    {
      names: ["star2"],
      surrogates: "🌟",
    },
    {
      names: ["dizzy"],
      surrogates: "💫",
    },
    {
      names: ["sparkles"],
      surrogates: "✨",
    },
    {
      names: ["comet"],
      surrogates: "☄",
    },
    {
      names: ["sunny"],
      surrogates: "☀",
    },
    {
      names: ["white_sun_small_cloud", "white_sun_with_small_cloud"],
      surrogates: "🌤",
    },
    {
      names: ["partly_sunny"],
      surrogates: "⛅",
    },
    {
      names: ["white_sun_cloud", "white_sun_behind_cloud"],
      surrogates: "🌥",
    },
    {
      names: ["white_sun_rain_cloud", "white_sun_behind_cloud_with_rain"],
      surrogates: "🌦",
    },
    {
      names: ["cloud"],
      surrogates: "☁",
    },
    {
      names: ["cloud_rain", "cloud_with_rain"],
      surrogates: "🌧",
    },
    {
      names: ["thunder_cloud_rain", "thunder_cloud_and_rain"],
      surrogates: "⛈",
    },
    {
      names: ["cloud_lightning", "cloud_with_lightning"],
      surrogates: "🌩",
    },
    {
      names: ["zap"],
      surrogates: "⚡",
    },
    {
      names: ["fire", "flame"],
      surrogates: "🔥",
    },
    {
      names: ["boom"],
      surrogates: "💥",
    },
    {
      names: ["snowflake"],
      surrogates: "❄",
    },
    {
      names: ["cloud_snow", "cloud_with_snow"],
      surrogates: "🌨",
    },
    {
      names: ["snowman2"],
      surrogates: "☃",
    },
    {
      names: ["snowman"],
      surrogates: "⛄",
    },
    {
      names: ["wind_blowing_face"],
      surrogates: "🌬",
    },
    {
      names: ["dash"],
      surrogates: "💨",
    },
    {
      names: ["cloud_tornado", "cloud_with_tornado"],
      surrogates: "🌪",
    },
    {
      names: ["fog"],
      surrogates: "🌫",
    },
    {
      names: ["umbrella2"],
      surrogates: "☂",
    },
    {
      names: ["umbrella"],
      surrogates: "☔",
    },
    {
      names: ["droplet"],
      surrogates: "💧",
    },
    {
      names: ["sweat_drops"],
      surrogates: "💦",
    },
    {
      names: ["ocean"],
      surrogates: "🌊",
    },
    {
      names: ["eagle"],
      surrogates: "🦅",
    },
    {
      names: ["duck"],
      surrogates: "🦆",
    },
    {
      names: ["bat"],
      surrogates: "🦇",
    },
    {
      names: ["shark"],
      surrogates: "🦈",
    },
    {
      names: ["owl"],
      surrogates: "🦉",
    },
    {
      names: ["fox", "fox_face"],
      surrogates: "🦊",
    },
    {
      names: ["butterfly"],
      surrogates: "🦋",
    },
    {
      names: ["deer"],
      surrogates: "🦌",
    },
    {
      names: ["gorilla"],
      surrogates: "🦍",
    },
    {
      names: ["lizard"],
      surrogates: "🦎",
    },
    {
      names: ["rhino", "rhinoceros"],
      surrogates: "🦏",
    },
    {
      names: ["wilted_rose", "wilted_flower"],
      surrogates: "🥀",
    },
    {
      names: ["shrimp"],
      surrogates: "🦐",
    },
    {
      names: ["squid"],
      surrogates: "🦑",
    },
  ],
  food: [
    {
      names: ["green_apple"],
      surrogates: "🍏",
    },
    {
      names: ["apple"],
      surrogates: "🍎",
    },
    {
      names: ["pear"],
      surrogates: "🍐",
    },
    {
      names: ["tangerine"],
      surrogates: "🍊",
    },
    {
      names: ["lemon"],
      surrogates: "🍋",
    },
    {
      names: ["banana"],
      surrogates: "🍌",
    },
    {
      names: ["watermelon"],
      surrogates: "🍉",
    },
    {
      names: ["grapes"],
      surrogates: "🍇",
    },
    {
      names: ["strawberry"],
      surrogates: "🍓",
    },
    {
      names: ["melon"],
      surrogates: "🍈",
    },
    {
      names: ["cherries"],
      surrogates: "🍒",
    },
    {
      names: ["peach"],
      surrogates: "🍑",
    },
    {
      names: ["pineapple"],
      surrogates: "🍍",
    },
    {
      names: ["tomato"],
      surrogates: "🍅",
    },
    {
      names: ["eggplant"],
      surrogates: "🍆",
    },
    {
      names: ["hot_pepper"],
      surrogates: "🌶",
    },
    {
      names: ["corn"],
      surrogates: "🌽",
    },
    {
      names: ["sweet_potato"],
      surrogates: "🍠",
    },
    {
      names: ["honey_pot"],
      surrogates: "🍯",
    },
    {
      names: ["bread"],
      surrogates: "🍞",
    },
    {
      names: ["cheese", "cheese_wedge"],
      surrogates: "🧀",
    },
    {
      names: ["poultry_leg"],
      surrogates: "🍗",
    },
    {
      names: ["meat_on_bone"],
      surrogates: "🍖",
    },
    {
      names: ["fried_shrimp"],
      surrogates: "🍤",
    },
    {
      names: ["cooking"],
      surrogates: "🍳",
    },
    {
      names: ["hamburger"],
      surrogates: "🍔",
    },
    {
      names: ["fries"],
      surrogates: "🍟",
    },
    {
      names: ["hotdog", "hot_dog"],
      surrogates: "🌭",
    },
    {
      names: ["pizza"],
      surrogates: "🍕",
    },
    {
      names: ["spaghetti"],
      surrogates: "🍝",
    },
    {
      names: ["taco"],
      surrogates: "🌮",
    },
    {
      names: ["burrito"],
      surrogates: "🌯",
    },
    {
      names: ["ramen"],
      surrogates: "🍜",
    },
    {
      names: ["stew"],
      surrogates: "🍲",
    },
    {
      names: ["fish_cake"],
      surrogates: "🍥",
    },
    {
      names: ["sushi"],
      surrogates: "🍣",
    },
    {
      names: ["bento"],
      surrogates: "🍱",
    },
    {
      names: ["curry"],
      surrogates: "🍛",
    },
    {
      names: ["rice_ball"],
      surrogates: "🍙",
    },
    {
      names: ["rice"],
      surrogates: "🍚",
    },
    {
      names: ["rice_cracker"],
      surrogates: "🍘",
    },
    {
      names: ["oden"],
      surrogates: "🍢",
    },
    {
      names: ["dango"],
      surrogates: "🍡",
    },
    {
      names: ["shaved_ice"],
      surrogates: "🍧",
    },
    {
      names: ["ice_cream"],
      surrogates: "🍨",
    },
    {
      names: ["icecream"],
      surrogates: "🍦",
    },
    {
      names: ["cake"],
      surrogates: "🍰",
    },
    {
      names: ["birthday"],
      surrogates: "🎂",
    },
    {
      names: ["custard", "pudding", "flan"],
      surrogates: "🍮",
    },
    {
      names: ["candy"],
      surrogates: "🍬",
    },
    {
      names: ["lollipop"],
      surrogates: "🍭",
    },
    {
      names: ["chocolate_bar"],
      surrogates: "🍫",
    },
    {
      names: ["popcorn"],
      surrogates: "🍿",
    },
    {
      names: ["doughnut"],
      surrogates: "🍩",
    },
    {
      names: ["cookie"],
      surrogates: "🍪",
    },
    {
      names: ["beer"],
      surrogates: "🍺",
    },
    {
      names: ["beers"],
      surrogates: "🍻",
    },
    {
      names: ["wine_glass"],
      surrogates: "🍷",
    },
    {
      names: ["cocktail"],
      surrogates: "🍸",
    },
    {
      names: ["tropical_drink"],
      surrogates: "🍹",
    },
    {
      names: ["champagne", "bottle_with_popping_cork"],
      surrogates: "🍾",
    },
    {
      names: ["sake"],
      surrogates: "🍶",
    },
    {
      names: ["tea"],
      surrogates: "🍵",
    },
    {
      names: ["coffee"],
      surrogates: "☕",
    },
    {
      names: ["baby_bottle"],
      surrogates: "🍼",
    },
    {
      names: ["fork_and_knife"],
      surrogates: "🍴",
    },
    {
      names: ["fork_knife_plate", "fork_and_knife_with_plate"],
      surrogates: "🍽",
    },
    {
      names: ["croissant"],
      surrogates: "🥐",
    },
    {
      names: ["avocado"],
      surrogates: "🥑",
    },
    {
      names: ["cucumber"],
      surrogates: "🥒",
    },
    {
      names: ["bacon"],
      surrogates: "🥓",
    },
    {
      names: ["potato"],
      surrogates: "🥔",
    },
    {
      names: ["carrot"],
      surrogates: "🥕",
    },
    {
      names: ["french_bread", "baguette_bread"],
      surrogates: "🥖",
    },
    {
      names: ["salad", "green_salad"],
      surrogates: "🥗",
    },
    {
      names: ["shallow_pan_of_food", "paella"],
      surrogates: "🥘",
    },
    {
      names: ["stuffed_flatbread", "stuffed_pita"],
      surrogates: "🥙",
    },
    {
      names: ["champagne_glass", "clinking_glass"],
      surrogates: "🥂",
    },
    {
      names: ["tumbler_glass", "whisky"],
      surrogates: "🥃",
    },
    {
      names: ["spoon"],
      surrogates: "🥄",
    },
    {
      names: ["egg"],
      surrogates: "🥚",
    },
    {
      names: ["milk", "glass_of_milk"],
      surrogates: "🥛",
    },
    {
      names: ["peanuts", "shelled_peanut"],
      surrogates: "🥜",
    },
    {
      names: ["kiwi", "kiwifruit"],
      surrogates: "🥝",
    },
    {
      names: ["pancakes"],
      surrogates: "🥞",
    },
  ],
  activity: [
    {
      names: ["soccer"],
      surrogates: "⚽",
    },
    {
      names: ["basketball"],
      surrogates: "🏀",
    },
    {
      names: ["football"],
      surrogates: "🏈",
    },
    {
      names: ["baseball"],
      surrogates: "⚾",
    },
    {
      names: ["tennis"],
      surrogates: "🎾",
    },
    {
      names: ["volleyball"],
      surrogates: "🏐",
    },
    {
      names: ["rugby_football"],
      surrogates: "🏉",
    },
    {
      names: ["8ball"],
      surrogates: "🎱",
    },
    {
      names: ["golf"],
      surrogates: "⛳",
    },
    {
      names: ["golfer"],
      surrogates: "🏌",
      hasDiversity: !0,
    },
    {
      names: ["ping_pong", "table_tennis"],
      surrogates: "🏓",
    },
    {
      names: ["badminton"],
      surrogates: "🏸",
    },
    {
      names: ["hockey"],
      surrogates: "🏒",
    },
    {
      names: ["field_hockey"],
      surrogates: "🏑",
    },
    {
      names: ["cricket", "cricket_bat_ball"],
      surrogates: "🏏",
    },
    {
      names: ["ski"],
      surrogates: "🎿",
    },
    {
      names: ["skier"],
      surrogates: "⛷",
      hasDiversity: !0,
    },
    {
      names: ["snowboarder"],
      surrogates: "🏂",
      hasDiversity: !0,
    },
    {
      names: ["ice_skate"],
      surrogates: "⛸",
    },
    {
      names: ["bow_and_arrow", "archery"],
      surrogates: "🏹",
    },
    {
      names: ["fishing_pole_and_fish"],
      surrogates: "🎣",
    },
    {
      names: ["rowboat"],
      surrogates: "🚣",
      hasDiversity: !0,
    },
    {
      names: ["swimmer"],
      surrogates: "🏊",
      hasDiversity: !0,
    },
    {
      names: ["surfer"],
      surrogates: "🏄",
      hasDiversity: !0,
    },
    {
      names: ["bath"],
      surrogates: "🛀",
      hasDiversity: !0,
    },
    {
      names: ["basketball_player", "person_with_ball"],
      surrogates: "⛹",
      hasDiversity: !0,
    },
    {
      names: ["lifter", "weight_lifter"],
      surrogates: "🏋",
      hasDiversity: !0,
    },
    {
      names: ["bicyclist"],
      surrogates: "🚴",
      hasDiversity: !0,
    },
    {
      names: ["mountain_bicyclist"],
      surrogates: "🚵",
      hasDiversity: !0,
    },
    {
      names: ["horse_racing"],
      surrogates: "🏇",
      hasDiversity: !0,
    },
    {
      names: ["levitate", "man_in_business_suit_levitating"],
      surrogates: "🕴",
      hasDiversity: !0,
    },
    {
      names: ["trophy"],
      surrogates: "🏆",
    },
    {
      names: ["running_shirt_with_sash"],
      surrogates: "🎽",
    },
    {
      names: ["medal", "sports_medal"],
      surrogates: "🏅",
    },
    {
      names: ["military_medal"],
      surrogates: "🎖",
    },
    {
      names: ["reminder_ribbon"],
      surrogates: "🎗",
    },
    {
      names: ["rosette"],
      surrogates: "🏵",
    },
    {
      names: ["ticket"],
      surrogates: "🎫",
    },
    {
      names: ["tickets", "admission_tickets"],
      surrogates: "🎟",
    },
    {
      names: ["performing_arts"],
      surrogates: "🎭",
    },
    {
      names: ["art"],
      surrogates: "🎨",
    },
    {
      names: ["circus_tent"],
      surrogates: "🎪",
    },
    {
      names: ["microphone"],
      surrogates: "🎤",
    },
    {
      names: ["headphones"],
      surrogates: "🎧",
    },
    {
      names: ["musical_score"],
      surrogates: "🎼",
    },
    {
      names: ["musical_keyboard"],
      surrogates: "🎹",
    },
    {
      names: ["saxophone"],
      surrogates: "🎷",
    },
    {
      names: ["trumpet"],
      surrogates: "🎺",
    },
    {
      names: ["guitar"],
      surrogates: "🎸",
    },
    {
      names: ["violin"],
      surrogates: "🎻",
    },
    {
      names: ["clapper"],
      surrogates: "🎬",
    },
    {
      names: ["video_game"],
      surrogates: "🎮",
    },
    {
      names: ["space_invader"],
      surrogates: "👾",
    },
    {
      names: ["dart"],
      surrogates: "🎯",
    },
    {
      names: ["game_die"],
      surrogates: "🎲",
    },
    {
      names: ["slot_machine"],
      surrogates: "🎰",
    },
    {
      names: ["bowling"],
      surrogates: "🎳",
    },
    {
      names: ["cartwheel", "person_doing_cartwheel"],
      surrogates: "🤸",
      hasDiversity: !0,
    },
    {
      names: ["juggling", "juggler"],
      surrogates: "🤹",
      hasDiversity: !0,
    },
    {
      names: ["wrestlers", "wrestling"],
      surrogates: "🤼",
      hasDiversity: !0,
    },
    {
      names: ["boxing_glove", "boxing_gloves"],
      surrogates: "🥊",
    },
    {
      names: ["martial_arts_uniform", "karate_uniform"],
      surrogates: "🥋",
    },
    {
      names: ["water_polo"],
      surrogates: "🤽",
      hasDiversity: !0,
    },
    {
      names: ["handball"],
      surrogates: "🤾",
      hasDiversity: !0,
    },
    {
      names: ["goal", "goal_net"],
      surrogates: "🥅",
    },
    {
      names: ["fencer", "fencing"],
      surrogates: "🤺",
    },
    {
      names: ["first_place", "first_place_medal"],
      surrogates: "🥇",
    },
    {
      names: ["second_place", "second_place_medal"],
      surrogates: "🥈",
    },
    {
      names: ["third_place", "third_place_medal"],
      surrogates: "🥉",
    },
    {
      names: ["drum", "drum_with_drumsticks"],
      surrogates: "🥁",
    },
  ],
  travel: [
    {
      names: ["red_car"],
      surrogates: "🚗",
    },
    {
      names: ["taxi"],
      surrogates: "🚕",
    },
    {
      names: ["blue_car"],
      surrogates: "🚙",
    },
    {
      names: ["bus"],
      surrogates: "🚌",
    },
    {
      names: ["trolleybus"],
      surrogates: "🚎",
    },
    {
      names: ["race_car", "racing_car"],
      surrogates: "🏎",
    },
    {
      names: ["police_car"],
      surrogates: "🚓",
    },
    {
      names: ["ambulance"],
      surrogates: "🚑",
    },
    {
      names: ["fire_engine"],
      surrogates: "🚒",
    },
    {
      names: ["minibus"],
      surrogates: "🚐",
    },
    {
      names: ["truck"],
      surrogates: "🚚",
    },
    {
      names: ["articulated_lorry"],
      surrogates: "🚛",
    },
    {
      names: ["tractor"],
      surrogates: "🚜",
    },
    {
      names: ["motorcycle", "racing_motorcycle"],
      surrogates: "🏍",
    },
    {
      names: ["bike"],
      surrogates: "🚲",
    },
    {
      names: ["rotating_light"],
      surrogates: "🚨",
    },
    {
      names: ["oncoming_police_car"],
      surrogates: "🚔",
    },
    {
      names: ["oncoming_bus"],
      surrogates: "🚍",
    },
    {
      names: ["oncoming_automobile"],
      surrogates: "🚘",
    },
    {
      names: ["oncoming_taxi"],
      surrogates: "🚖",
    },
    {
      names: ["aerial_tramway"],
      surrogates: "🚡",
    },
    {
      names: ["mountain_cableway"],
      surrogates: "🚠",
    },
    {
      names: ["suspension_railway"],
      surrogates: "🚟",
    },
    {
      names: ["railway_car"],
      surrogates: "🚃",
    },
    {
      names: ["train"],
      surrogates: "🚋",
    },
    {
      names: ["monorail"],
      surrogates: "🚝",
    },
    {
      names: ["bullettrain_side"],
      surrogates: "🚄",
    },
    {
      names: ["bullettrain_front"],
      surrogates: "🚅",
    },
    {
      names: ["light_rail"],
      surrogates: "🚈",
    },
    {
      names: ["mountain_railway"],
      surrogates: "🚞",
    },
    {
      names: ["steam_locomotive"],
      surrogates: "🚂",
    },
    {
      names: ["train2"],
      surrogates: "🚆",
    },
    {
      names: ["metro"],
      surrogates: "🚇",
    },
    {
      names: ["tram"],
      surrogates: "🚊",
    },
    {
      names: ["station"],
      surrogates: "🚉",
    },
    {
      names: ["helicopter"],
      surrogates: "🚁",
    },
    {
      names: ["airplane_small", "small_airplane"],
      surrogates: "🛩",
    },
    {
      names: ["airplane"],
      surrogates: "✈",
    },
    {
      names: ["airplane_departure"],
      surrogates: "🛫",
    },
    {
      names: ["airplane_arriving"],
      surrogates: "🛬",
    },
    {
      names: ["sailboat"],
      surrogates: "⛵",
    },
    {
      names: ["motorboat"],
      surrogates: "🛥",
    },
    {
      names: ["speedboat"],
      surrogates: "🚤",
    },
    {
      names: ["ferry"],
      surrogates: "⛴",
    },
    {
      names: ["cruise_ship", "passenger_ship"],
      surrogates: "🛳",
    },
    {
      names: ["rocket"],
      surrogates: "🚀",
    },
    {
      names: ["satellite_orbital"],
      surrogates: "🛰",
    },
    {
      names: ["seat"],
      surrogates: "💺",
    },
    {
      names: ["anchor"],
      surrogates: "⚓",
    },
    {
      names: ["construction"],
      surrogates: "🚧",
    },
    {
      names: ["fuelpump"],
      surrogates: "⛽",
    },
    {
      names: ["busstop"],
      surrogates: "🚏",
    },
    {
      names: ["vertical_traffic_light"],
      surrogates: "🚦",
    },
    {
      names: ["traffic_light"],
      surrogates: "🚥",
    },
    {
      names: ["checkered_flag"],
      surrogates: "🏁",
    },
    {
      names: ["ship"],
      surrogates: "🚢",
    },
    {
      names: ["ferris_wheel"],
      surrogates: "🎡",
    },
    {
      names: ["roller_coaster"],
      surrogates: "🎢",
    },
    {
      names: ["carousel_horse"],
      surrogates: "🎠",
    },
    {
      names: ["construction_site", "building_construction"],
      surrogates: "🏗",
    },
    {
      names: ["foggy"],
      surrogates: "🌁",
    },
    {
      names: ["tokyo_tower"],
      surrogates: "🗼",
    },
    {
      names: ["factory"],
      surrogates: "🏭",
    },
    {
      names: ["fountain"],
      surrogates: "⛲",
    },
    {
      names: ["rice_scene"],
      surrogates: "🎑",
    },
    {
      names: ["mountain"],
      surrogates: "⛰",
    },
    {
      names: ["mountain_snow", "snow_capped_mountain"],
      surrogates: "🏔",
    },
    {
      names: ["mount_fuji"],
      surrogates: "🗻",
    },
    {
      names: ["volcano"],
      surrogates: "🌋",
    },
    {
      names: ["japan"],
      surrogates: "🗾",
    },
    {
      names: ["camping"],
      surrogates: "🏕",
    },
    {
      names: ["tent"],
      surrogates: "⛺",
    },
    {
      names: ["park", "national_park"],
      surrogates: "🏞",
    },
    {
      names: ["motorway"],
      surrogates: "🛣",
    },
    {
      names: ["railway_track", "railroad_track"],
      surrogates: "🛤",
    },
    {
      names: ["sunrise"],
      surrogates: "🌅",
    },
    {
      names: ["sunrise_over_mountains"],
      surrogates: "🌄",
    },
    {
      names: ["desert"],
      surrogates: "🏜",
    },
    {
      names: ["beach", "beach_with_umbrella"],
      surrogates: "🏖",
    },
    {
      names: ["island", "desert_island"],
      surrogates: "🏝",
    },
    {
      names: ["city_sunset", "city_sunrise"],
      surrogates: "🌇",
    },
    {
      names: ["city_dusk"],
      surrogates: "🌆",
    },
    {
      names: ["cityscape"],
      surrogates: "🏙",
    },
    {
      names: ["night_with_stars"],
      surrogates: "🌃",
    },
    {
      names: ["bridge_at_night"],
      surrogates: "🌉",
    },
    {
      names: ["milky_way"],
      surrogates: "🌌",
    },
    {
      names: ["stars"],
      surrogates: "🌠",
    },
    {
      names: ["sparkler"],
      surrogates: "🎇",
    },
    {
      names: ["fireworks"],
      surrogates: "🎆",
    },
    {
      names: ["rainbow"],
      surrogates: "🌈",
    },
    {
      names: ["homes", "house_buildings"],
      surrogates: "🏘",
    },
    {
      names: ["european_castle"],
      surrogates: "🏰",
    },
    {
      names: ["japanese_castle"],
      surrogates: "🏯",
    },
    {
      names: ["stadium"],
      surrogates: "🏟",
    },
    {
      names: ["statue_of_liberty"],
      surrogates: "🗽",
    },
    {
      names: ["house"],
      surrogates: "🏠",
    },
    {
      names: ["house_with_garden"],
      surrogates: "🏡",
    },
    {
      names: ["house_abandoned", "derelict_house_building"],
      surrogates: "🏚",
    },
    {
      names: ["office"],
      surrogates: "🏢",
    },
    {
      names: ["department_store"],
      surrogates: "🏬",
    },
    {
      names: ["post_office"],
      surrogates: "🏣",
    },
    {
      names: ["european_post_office"],
      surrogates: "🏤",
    },
    {
      names: ["hospital"],
      surrogates: "🏥",
    },
    {
      names: ["bank"],
      surrogates: "🏦",
    },
    {
      names: ["hotel"],
      surrogates: "🏨",
    },
    {
      names: ["convenience_store"],
      surrogates: "🏪",
    },
    {
      names: ["school"],
      surrogates: "🏫",
    },
    {
      names: ["love_hotel"],
      surrogates: "🏩",
    },
    {
      names: ["wedding"],
      surrogates: "💒",
    },
    {
      names: ["classical_building"],
      surrogates: "🏛",
    },
    {
      names: ["church"],
      surrogates: "⛪",
    },
    {
      names: ["mosque"],
      surrogates: "🕌",
    },
    {
      names: ["synagogue"],
      surrogates: "🕍",
    },
    {
      names: ["kaaba"],
      surrogates: "🕋",
    },
    {
      names: ["shinto_shrine"],
      surrogates: "⛩",
    },
    {
      names: ["scooter"],
      surrogates: "🛴",
    },
    {
      names: ["motor_scooter", "motorbike"],
      surrogates: "🛵",
    },
    {
      names: ["canoe", "kayak"],
      surrogates: "🛶",
    },
  ],
  objects: [
    {
      names: ["watch"],
      surrogates: "⌚",
    },
    {
      names: ["iphone"],
      surrogates: "📱",
    },
    {
      names: ["calling"],
      surrogates: "📲",
    },
    {
      names: ["computer"],
      surrogates: "💻",
    },
    {
      names: ["keyboard"],
      surrogates: "⌨",
    },
    {
      names: ["desktop", "desktop_computer"],
      surrogates: "🖥",
    },
    {
      names: ["printer"],
      surrogates: "🖨",
    },
    {
      names: ["mouse_three_button", "three_button_mouse"],
      surrogates: "🖱",
    },
    {
      names: ["trackball"],
      surrogates: "🖲",
    },
    {
      names: ["joystick"],
      surrogates: "🕹",
    },
    {
      names: ["compression"],
      surrogates: "🗜",
    },
    {
      names: ["minidisc"],
      surrogates: "💽",
    },
    {
      names: ["floppy_disk"],
      surrogates: "💾",
    },
    {
      names: ["cd"],
      surrogates: "💿",
    },
    {
      names: ["dvd"],
      surrogates: "📀",
    },
    {
      names: ["vhs"],
      surrogates: "📼",
    },
    {
      names: ["camera"],
      surrogates: "📷",
    },
    {
      names: ["camera_with_flash"],
      surrogates: "📸",
    },
    {
      names: ["video_camera"],
      surrogates: "📹",
    },
    {
      names: ["movie_camera"],
      surrogates: "🎥",
    },
    {
      names: ["projector", "film_projector"],
      surrogates: "📽",
    },
    {
      names: ["film_frames"],
      surrogates: "🎞",
    },
    {
      names: ["telephone_receiver"],
      surrogates: "📞",
    },
    {
      names: ["telephone"],
      surrogates: "☎",
    },
    {
      names: ["pager"],
      surrogates: "📟",
    },
    {
      names: ["fax"],
      surrogates: "📠",
    },
    {
      names: ["tv"],
      surrogates: "📺",
    },
    {
      names: ["radio"],
      surrogates: "📻",
    },
    {
      names: ["microphone2", "studio_microphone"],
      surrogates: "🎙",
    },
    {
      names: ["level_slider"],
      surrogates: "🎚",
    },
    {
      names: ["control_knobs"],
      surrogates: "🎛",
    },
    {
      names: ["stopwatch"],
      surrogates: "⏱",
    },
    {
      names: ["timer", "timer_clock"],
      surrogates: "⏲",
    },
    {
      names: ["alarm_clock"],
      surrogates: "⏰",
    },
    {
      names: ["clock", "mantlepiece_clock"],
      surrogates: "🕰",
    },
    {
      names: ["hourglass_flowing_sand"],
      surrogates: "⏳",
    },
    {
      names: ["hourglass"],
      surrogates: "⌛",
    },
    {
      names: ["satellite"],
      surrogates: "📡",
    },
    {
      names: ["battery"],
      surrogates: "🔋",
    },
    {
      names: ["electric_plug"],
      surrogates: "🔌",
    },
    {
      names: ["bulb"],
      surrogates: "💡",
    },
    {
      names: ["flashlight"],
      surrogates: "🔦",
    },
    {
      names: ["candle"],
      surrogates: "🕯",
    },
    {
      names: ["wastebasket"],
      surrogates: "🗑",
    },
    {
      names: ["oil", "oil_drum"],
      surrogates: "🛢",
    },
    {
      names: ["money_with_wings"],
      surrogates: "💸",
    },
    {
      names: ["dollar"],
      surrogates: "💵",
    },
    {
      names: ["yen"],
      surrogates: "💴",
    },
    {
      names: ["euro"],
      surrogates: "💶",
    },
    {
      names: ["pound"],
      surrogates: "💷",
    },
    {
      names: ["moneybag"],
      surrogates: "💰",
    },
    {
      names: ["credit_card"],
      surrogates: "💳",
    },
    {
      names: ["gem"],
      surrogates: "💎",
    },
    {
      names: ["scales"],
      surrogates: "⚖",
    },
    {
      names: ["wrench"],
      surrogates: "🔧",
    },
    {
      names: ["hammer"],
      surrogates: "🔨",
    },
    {
      names: ["hammer_pick", "hammer_and_pick"],
      surrogates: "⚒",
    },
    {
      names: ["tools", "hammer_and_wrench"],
      surrogates: "🛠",
    },
    {
      names: ["pick"],
      surrogates: "⛏",
    },
    {
      names: ["nut_and_bolt"],
      surrogates: "🔩",
    },
    {
      names: ["gear"],
      surrogates: "⚙",
    },
    {
      names: ["chains"],
      surrogates: "⛓",
    },
    {
      names: ["gun"],
      surrogates: "🔫",
    },
    {
      names: ["bomb"],
      surrogates: "💣",
    },
    {
      names: ["knife"],
      surrogates: "🔪",
    },
    {
      names: ["dagger", "dagger_knife"],
      surrogates: "🗡",
    },
    {
      names: ["crossed_swords"],
      surrogates: "⚔",
    },
    {
      names: ["shield"],
      surrogates: "🛡",
    },
    {
      names: ["smoking"],
      surrogates: "🚬",
    },
    {
      names: ["skull_crossbones", "skull_and_crossbones"],
      surrogates: "☠",
    },
    {
      names: ["coffin"],
      surrogates: "⚰",
    },
    {
      names: ["urn", "funeral_urn"],
      surrogates: "⚱",
    },
    {
      names: ["amphora"],
      surrogates: "🏺",
    },
    {
      names: ["crystal_ball"],
      surrogates: "🔮",
    },
    {
      names: ["prayer_beads"],
      surrogates: "📿",
    },
    {
      names: ["barber"],
      surrogates: "💈",
    },
    {
      names: ["alembic"],
      surrogates: "⚗",
    },
    {
      names: ["telescope"],
      surrogates: "🔭",
    },
    {
      names: ["microscope"],
      surrogates: "🔬",
    },
    {
      names: ["hole"],
      surrogates: "🕳",
    },
    {
      names: ["pill"],
      surrogates: "💊",
    },
    {
      names: ["syringe"],
      surrogates: "💉",
    },
    {
      names: ["thermometer"],
      surrogates: "🌡",
    },
    {
      names: ["label"],
      surrogates: "🏷",
    },
    {
      names: ["bookmark"],
      surrogates: "🔖",
    },
    {
      names: ["toilet"],
      surrogates: "🚽",
    },
    {
      names: ["shower"],
      surrogates: "🚿",
    },
    {
      names: ["bathtub"],
      surrogates: "🛁",
    },
    {
      names: ["key"],
      surrogates: "🔑",
    },
    {
      names: ["key2", "old_key"],
      surrogates: "🗝",
    },
    {
      names: ["couch", "couch_and_lamp"],
      surrogates: "🛋",
    },
    {
      names: ["sleeping_accommodation"],
      surrogates: "🛌",
      hasDiversity: !0,
    },
    {
      names: ["bed"],
      surrogates: "🛏",
    },
    {
      names: ["door"],
      surrogates: "🚪",
    },
    {
      names: ["bellhop", "bellhop_bell"],
      surrogates: "🛎",
    },
    {
      names: ["frame_photo", "frame_with_picture"],
      surrogates: "🖼",
    },
    {
      names: ["map", "world_map"],
      surrogates: "🗺",
    },
    {
      names: ["beach_umbrella", "umbrella_on_ground"],
      surrogates: "⛱",
    },
    {
      names: ["moyai"],
      surrogates: "🗿",
    },
    {
      names: ["shopping_bags"],
      surrogates: "🛍",
    },
    {
      names: ["balloon"],
      surrogates: "🎈",
    },
    {
      names: ["flags"],
      surrogates: "🎏",
    },
    {
      names: ["ribbon"],
      surrogates: "🎀",
    },
    {
      names: ["gift"],
      surrogates: "🎁",
    },
    {
      names: ["confetti_ball"],
      surrogates: "🎊",
    },
    {
      names: ["tada"],
      surrogates: "🎉",
    },
    {
      names: ["dolls"],
      surrogates: "🎎",
    },
    {
      names: ["wind_chime"],
      surrogates: "🎐",
    },
    {
      names: ["crossed_flags"],
      surrogates: "🎌",
    },
    {
      names: ["izakaya_lantern"],
      surrogates: "🏮",
    },
    {
      names: ["envelope"],
      surrogates: "✉",
    },
    {
      names: ["envelope_with_arrow"],
      surrogates: "📩",
    },
    {
      names: ["incoming_envelope"],
      surrogates: "📨",
    },
    {
      names: ["e_mail", "email"],
      surrogates: "📧",
    },
    {
      names: ["love_letter"],
      surrogates: "💌",
    },
    {
      names: ["postbox"],
      surrogates: "📮",
    },
    {
      names: ["mailbox_closed"],
      surrogates: "📪",
    },
    {
      names: ["mailbox"],
      surrogates: "📫",
    },
    {
      names: ["mailbox_with_mail"],
      surrogates: "📬",
    },
    {
      names: ["mailbox_with_no_mail"],
      surrogates: "📭",
    },
    {
      names: ["package"],
      surrogates: "📦",
    },
    {
      names: ["postal_horn"],
      surrogates: "📯",
    },
    {
      names: ["inbox_tray"],
      surrogates: "📥",
    },
    {
      names: ["outbox_tray"],
      surrogates: "📤",
    },
    {
      names: ["scroll"],
      surrogates: "📜",
    },
    {
      names: ["page_with_curl"],
      surrogates: "📃",
    },
    {
      names: ["bookmark_tabs"],
      surrogates: "📑",
    },
    {
      names: ["bar_chart"],
      surrogates: "📊",
    },
    {
      names: ["chart_with_upwards_trend"],
      surrogates: "📈",
    },
    {
      names: ["chart_with_downwards_trend"],
      surrogates: "📉",
    },
    {
      names: ["page_facing_up"],
      surrogates: "📄",
    },
    {
      names: ["date"],
      surrogates: "📅",
    },
    {
      names: ["calendar"],
      surrogates: "📆",
    },
    {
      names: ["calendar_spiral", "spiral_calendar_pad"],
      surrogates: "🗓",
    },
    {
      names: ["card_index"],
      surrogates: "📇",
    },
    {
      names: ["card_box", "card_file_box"],
      surrogates: "🗃",
    },
    {
      names: ["ballot_box", "ballot_box_with_ballot"],
      surrogates: "🗳",
    },
    {
      names: ["file_cabinet"],
      surrogates: "🗄",
    },
    {
      names: ["clipboard"],
      surrogates: "📋",
    },
    {
      names: ["notepad_spiral", "spiral_note_pad"],
      surrogates: "🗒",
    },
    {
      names: ["file_folder"],
      surrogates: "📁",
    },
    {
      names: ["open_file_folder"],
      surrogates: "📂",
    },
    {
      names: ["dividers", "card_index_dividers"],
      surrogates: "🗂",
    },
    {
      names: ["newspaper2", "rolled_up_newspaper"],
      surrogates: "🗞",
    },
    {
      names: ["newspaper"],
      surrogates: "📰",
    },
    {
      names: ["notebook"],
      surrogates: "📓",
    },
    {
      names: ["closed_book"],
      surrogates: "📕",
    },
    {
      names: ["green_book"],
      surrogates: "📗",
    },
    {
      names: ["blue_book"],
      surrogates: "📘",
    },
    {
      names: ["orange_book"],
      surrogates: "📙",
    },
    {
      names: ["notebook_with_decorative_cover"],
      surrogates: "📔",
    },
    {
      names: ["ledger"],
      surrogates: "📒",
    },
    {
      names: ["books"],
      surrogates: "📚",
    },
    {
      names: ["book"],
      surrogates: "📖",
    },
    {
      names: ["link"],
      surrogates: "🔗",
    },
    {
      names: ["paperclip"],
      surrogates: "📎",
    },
    {
      names: ["paperclips", "linked_paperclips"],
      surrogates: "🖇",
    },
    {
      names: ["scissors"],
      surrogates: "✂",
    },
    {
      names: ["triangular_ruler"],
      surrogates: "📐",
    },
    {
      names: ["straight_ruler"],
      surrogates: "📏",
    },
    {
      names: ["pushpin"],
      surrogates: "📌",
    },
    {
      names: ["round_pushpin"],
      surrogates: "📍",
    },
    {
      names: ["triangular_flag_on_post"],
      surrogates: "🚩",
    },
    {
      names: ["flag_white"],
      surrogates: "🏳",
    },
    {
      names: ["flag_black"],
      surrogates: "🏴",
    },
    {
      names: ["closed_lock_with_key"],
      surrogates: "🔐",
    },
    {
      names: ["lock"],
      surrogates: "🔒",
    },
    {
      names: ["unlock"],
      surrogates: "🔓",
    },
    {
      names: ["lock_with_ink_pen"],
      surrogates: "🔏",
    },
    {
      names: ["pen_ballpoint", "lower_left_ballpoint_pen"],
      surrogates: "🖊",
    },
    {
      names: ["pen_fountain", "lower_left_fountain_pen"],
      surrogates: "🖋",
    },
    {
      names: ["black_nib"],
      surrogates: "✒",
    },
    {
      names: ["pencil"],
      surrogates: "📝",
    },
    {
      names: ["pencil2"],
      surrogates: "✏",
    },
    {
      names: ["crayon", "lower_left_crayon"],
      surrogates: "🖍",
    },
    {
      names: ["paintbrush", "lower_left_paintbrush"],
      surrogates: "🖌",
    },
    {
      names: ["mag"],
      surrogates: "🔍",
    },
    {
      names: ["mag_right"],
      surrogates: "🔎",
    },
    {
      names: ["shopping_cart", "shopping_trolley"],
      surrogates: "🛒",
    },
  ],
  symbols: [
    {
      names: ["100"],
      surrogates: "💯",
    },
    {
      names: ["1234"],
      surrogates: "🔢",
    },
    {
      names: ["heart"],
      surrogates: "❤",
    },
    {
      names: ["yellow_heart"],
      surrogates: "💛",
    },
    {
      names: ["green_heart"],
      surrogates: "💚",
    },
    {
      names: ["blue_heart"],
      surrogates: "💙",
    },
    {
      names: ["purple_heart"],
      surrogates: "💜",
    },
    {
      names: ["broken_heart"],
      surrogates: "💔",
    },
    {
      names: ["heart_exclamation", "heavy_heart_exclamation_mark_ornament"],
      surrogates: "❣",
    },
    {
      names: ["two_hearts"],
      surrogates: "💕",
    },
    {
      names: ["revolving_hearts"],
      surrogates: "💞",
    },
    {
      names: ["heartbeat"],
      surrogates: "💓",
    },
    {
      names: ["heartpulse"],
      surrogates: "💗",
    },
    {
      names: ["sparkling_heart"],
      surrogates: "💖",
    },
    {
      names: ["cupid"],
      surrogates: "💘",
    },
    {
      names: ["gift_heart"],
      surrogates: "💝",
    },
    {
      names: ["heart_decoration"],
      surrogates: "💟",
    },
    {
      names: ["peace", "peace_symbol"],
      surrogates: "☮",
    },
    {
      names: ["cross", "latin_cross"],
      surrogates: "✝",
    },
    {
      names: ["star_and_crescent"],
      surrogates: "☪",
    },
    {
      names: ["om_symbol"],
      surrogates: "🕉",
    },
    {
      names: ["wheel_of_dharma"],
      surrogates: "☸",
    },
    {
      names: ["star_of_david"],
      surrogates: "✡",
    },
    {
      names: ["six_pointed_star"],
      surrogates: "🔯",
    },
    {
      names: ["menorah"],
      surrogates: "🕎",
    },
    {
      names: ["yin_yang"],
      surrogates: "☯",
    },
    {
      names: ["orthodox_cross"],
      surrogates: "☦",
    },
    {
      names: ["place_of_worship", "worship_symbol"],
      surrogates: "🛐",
    },
    {
      names: ["ophiuchus"],
      surrogates: "⛎",
    },
    {
      names: ["aries"],
      surrogates: "♈",
    },
    {
      names: ["taurus"],
      surrogates: "♉",
    },
    {
      names: ["gemini"],
      surrogates: "♊",
    },
    {
      names: ["cancer"],
      surrogates: "♋",
    },
    {
      names: ["leo"],
      surrogates: "♌",
    },
    {
      names: ["virgo"],
      surrogates: "♍",
    },
    {
      names: ["libra"],
      surrogates: "♎",
    },
    {
      names: ["scorpius"],
      surrogates: "♏",
    },
    {
      names: ["sagittarius"],
      surrogates: "♐",
    },
    {
      names: ["capricorn"],
      surrogates: "♑",
    },
    {
      names: ["aquarius"],
      surrogates: "♒",
    },
    {
      names: ["pisces"],
      surrogates: "♓",
    },
    {
      names: ["id"],
      surrogates: "🆔",
    },
    {
      names: ["atom", "atom_symbol"],
      surrogates: "⚛",
    },
    {
      names: ["u7a7a"],
      surrogates: "🈳",
    },
    {
      names: ["u5272"],
      surrogates: "🈹",
    },
    {
      names: ["radioactive", "radioactive_sign"],
      surrogates: "☢",
    },
    {
      names: ["biohazard", "biohazard_sign"],
      surrogates: "☣",
    },
    {
      names: ["mobile_phone_off"],
      surrogates: "📴",
    },
    {
      names: ["vibration_mode"],
      surrogates: "📳",
    },
    {
      names: ["u6709"],
      surrogates: "🈶",
    },
    {
      names: ["u7121"],
      surrogates: "🈚",
    },
    {
      names: ["u7533"],
      surrogates: "🈸",
    },
    {
      names: ["u55b6"],
      surrogates: "🈺",
    },
    {
      names: ["u6708"],
      surrogates: "🈷",
    },
    {
      names: ["eight_pointed_black_star"],
      surrogates: "✴",
    },
    {
      names: ["vs"],
      surrogates: "🆚",
    },
    {
      names: ["accept"],
      surrogates: "🉑",
    },
    {
      names: ["white_flower"],
      surrogates: "💮",
    },
    {
      names: ["ideograph_advantage"],
      surrogates: "🉐",
    },
    {
      names: ["secret"],
      surrogates: "㊙",
    },
    {
      names: ["congratulations"],
      surrogates: "㊗",
    },
    {
      names: ["u5408"],
      surrogates: "🈴",
    },
    {
      names: ["u6e80"],
      surrogates: "🈵",
    },
    {
      names: ["u7981"],
      surrogates: "🈲",
    },
    {
      names: ["a"],
      surrogates: "🅰",
    },
    {
      names: ["b"],
      surrogates: "🅱",
    },
    {
      names: ["ab"],
      surrogates: "🆎",
    },
    {
      names: ["cl"],
      surrogates: "🆑",
    },
    {
      names: ["o2"],
      surrogates: "🅾",
    },
    {
      names: ["sos"],
      surrogates: "🆘",
    },
    {
      names: ["no_entry"],
      surrogates: "⛔",
    },
    {
      names: ["name_badge"],
      surrogates: "📛",
    },
    {
      names: ["no_entry_sign"],
      surrogates: "🚫",
    },
    {
      names: ["x"],
      surrogates: "❌",
    },
    {
      names: ["o"],
      surrogates: "⭕",
    },
    {
      names: ["anger"],
      surrogates: "💢",
    },
    {
      names: ["hotsprings"],
      surrogates: "♨",
    },
    {
      names: ["no_pedestrians"],
      surrogates: "🚷",
    },
    {
      names: ["do_not_litter"],
      surrogates: "🚯",
    },
    {
      names: ["no_bicycles"],
      surrogates: "🚳",
    },
    {
      names: ["non_potable_water"],
      surrogates: "🚱",
    },
    {
      names: ["underage"],
      surrogates: "🔞",
    },
    {
      names: ["no_mobile_phones"],
      surrogates: "📵",
    },
    {
      names: ["exclamation"],
      surrogates: "❗",
    },
    {
      names: ["grey_exclamation"],
      surrogates: "❕",
    },
    {
      names: ["question"],
      surrogates: "❓",
    },
    {
      names: ["grey_question"],
      surrogates: "❔",
    },
    {
      names: ["bangbang"],
      surrogates: "‼",
    },
    {
      names: ["interrobang"],
      surrogates: "⁉",
    },
    {
      names: ["low_brightness"],
      surrogates: "🔅",
    },
    {
      names: ["high_brightness"],
      surrogates: "🔆",
    },
    {
      names: ["trident"],
      surrogates: "🔱",
    },
    {
      names: ["fleur_de_lis"],
      surrogates: "⚜",
    },
    {
      names: ["part_alternation_mark"],
      surrogates: "〽",
    },
    {
      names: ["warning"],
      surrogates: "⚠",
    },
    {
      names: ["children_crossing"],
      surrogates: "🚸",
    },
    {
      names: ["beginner"],
      surrogates: "🔰",
    },
    {
      names: ["recycle"],
      surrogates: "♻",
    },
    {
      names: ["u6307"],
      surrogates: "🈯",
    },
    {
      names: ["chart"],
      surrogates: "💹",
    },
    {
      names: ["sparkle"],
      surrogates: "❇",
    },
    {
      names: ["eight_spoked_asterisk"],
      surrogates: "✳",
    },
    {
      names: ["negative_squared_cross_mark"],
      surrogates: "❎",
    },
    {
      names: ["white_check_mark"],
      surrogates: "✅",
    },
    {
      names: ["diamond_shape_with_a_dot_inside"],
      surrogates: "💠",
    },
    {
      names: ["cyclone"],
      surrogates: "🌀",
    },
    {
      names: ["loop"],
      surrogates: "➿",
    },
    {
      names: ["globe_with_meridians"],
      surrogates: "🌐",
    },
    {
      names: ["m"],
      surrogates: "Ⓜ",
    },
    {
      names: ["atm"],
      surrogates: "🏧",
    },
    {
      names: ["sa"],
      surrogates: "🈂",
    },
    {
      names: ["passport_control"],
      surrogates: "🛂",
    },
    {
      names: ["customs"],
      surrogates: "🛃",
    },
    {
      names: ["baggage_claim"],
      surrogates: "🛄",
    },
    {
      names: ["left_luggage"],
      surrogates: "🛅",
    },
    {
      names: ["wheelchair"],
      surrogates: "♿",
    },
    {
      names: ["no_smoking"],
      surrogates: "🚭",
    },
    {
      names: ["wc"],
      surrogates: "🚾",
    },
    {
      names: ["parking"],
      surrogates: "🅿",
    },
    {
      names: ["potable_water"],
      surrogates: "🚰",
    },
    {
      names: ["mens"],
      surrogates: "🚹",
    },
    {
      names: ["womens"],
      surrogates: "🚺",
    },
    {
      names: ["baby_symbol"],
      surrogates: "🚼",
    },
    {
      names: ["restroom"],
      surrogates: "🚻",
    },
    {
      names: ["put_litter_in_its_place"],
      surrogates: "🚮",
    },
    {
      names: ["cinema"],
      surrogates: "🎦",
    },
    {
      names: ["signal_strength"],
      surrogates: "📶",
    },
    {
      names: ["koko"],
      surrogates: "🈁",
    },
    {
      names: ["ng"],
      surrogates: "🆖",
    },
    {
      names: ["ok"],
      surrogates: "🆗",
    },
    {
      names: ["up"],
      surrogates: "🆙",
    },
    {
      names: ["cool"],
      surrogates: "🆒",
    },
    {
      names: ["new"],
      surrogates: "🆕",
    },
    {
      names: ["free"],
      surrogates: "🆓",
    },
    {
      names: ["zero"],
      surrogates: "0⃣",
    },
    {
      names: ["one"],
      surrogates: "1⃣",
    },
    {
      names: ["two"],
      surrogates: "2⃣",
    },
    {
      names: ["three"],
      surrogates: "3⃣",
    },
    {
      names: ["four"],
      surrogates: "4⃣",
    },
    {
      names: ["five"],
      surrogates: "5⃣",
    },
    {
      names: ["six"],
      surrogates: "6⃣",
    },
    {
      names: ["seven"],
      surrogates: "7⃣",
    },
    {
      names: ["eight"],
      surrogates: "8⃣",
    },
    {
      names: ["nine"],
      surrogates: "9⃣",
    },
    {
      names: ["keycap_ten"],
      surrogates: "🔟",
    },
    {
      names: ["arrow_forward"],
      surrogates: "▶",
    },
    {
      names: ["pause_button", "double_vertical_bar"],
      surrogates: "⏸",
    },
    {
      names: ["play_pause"],
      surrogates: "⏯",
    },
    {
      names: ["stop_button"],
      surrogates: "⏹",
    },
    {
      names: ["record_button"],
      surrogates: "⏺",
    },
    {
      names: ["track_next", "next_track"],
      surrogates: "⏭",
    },
    {
      names: ["track_previous", "previous_track"],
      surrogates: "⏮",
    },
    {
      names: ["fast_forward"],
      surrogates: "⏩",
    },
    {
      names: ["rewind"],
      surrogates: "⏪",
    },
    {
      names: ["twisted_rightwards_arrows"],
      surrogates: "🔀",
    },
    {
      names: ["repeat"],
      surrogates: "🔁",
    },
    {
      names: ["repeat_one"],
      surrogates: "🔂",
    },
    {
      names: ["arrow_backward"],
      surrogates: "◀",
    },
    {
      names: ["arrow_up_small"],
      surrogates: "🔼",
    },
    {
      names: ["arrow_down_small"],
      surrogates: "🔽",
    },
    {
      names: ["arrow_double_up"],
      surrogates: "⏫",
    },
    {
      names: ["arrow_double_down"],
      surrogates: "⏬",
    },
    {
      names: ["arrow_right"],
      surrogates: "➡",
    },
    {
      names: ["arrow_left"],
      surrogates: "⬅",
    },
    {
      names: ["arrow_up"],
      surrogates: "⬆",
    },
    {
      names: ["arrow_down"],
      surrogates: "⬇",
    },
    {
      names: ["arrow_upper_right"],
      surrogates: "↗",
    },
    {
      names: ["arrow_lower_right"],
      surrogates: "↘",
    },
    {
      names: ["arrow_lower_left"],
      surrogates: "↙",
    },
    {
      names: ["arrow_upper_left"],
      surrogates: "↖",
    },
    {
      names: ["arrow_up_down"],
      surrogates: "↕",
    },
    {
      names: ["left_right_arrow"],
      surrogates: "↔",
    },
    {
      names: ["arrows_counterclockwise"],
      surrogates: "🔄",
    },
    {
      names: ["arrow_right_hook"],
      surrogates: "↪",
    },
    {
      names: ["leftwards_arrow_with_hook"],
      surrogates: "↩",
    },
    {
      names: ["arrow_heading_up"],
      surrogates: "⤴",
    },
    {
      names: ["arrow_heading_down"],
      surrogates: "⤵",
    },
    {
      names: ["hash"],
      surrogates: "#⃣",
    },
    {
      names: ["asterisk", "keycap_asterisk"],
      surrogates: "*⃣",
    },
    {
      names: ["information_source"],
      surrogates: "ℹ",
    },
    {
      names: ["abc"],
      surrogates: "🔤",
    },
    {
      names: ["abcd"],
      surrogates: "🔡",
    },
    {
      names: ["capital_abcd"],
      surrogates: "🔠",
    },
    {
      names: ["symbols"],
      surrogates: "🔣",
    },
    {
      names: ["musical_note"],
      surrogates: "🎵",
    },
    {
      names: ["notes"],
      surrogates: "🎶",
    },
    {
      names: ["wavy_dash"],
      surrogates: "〰",
    },
    {
      names: ["curly_loop"],
      surrogates: "➰",
    },
    {
      names: ["heavy_check_mark"],
      surrogates: "✔",
    },
    {
      names: ["arrows_clockwise"],
      surrogates: "🔃",
    },
    {
      names: ["heavy_plus_sign"],
      surrogates: "➕",
    },
    {
      names: ["heavy_minus_sign"],
      surrogates: "➖",
    },
    {
      names: ["heavy_division_sign"],
      surrogates: "➗",
    },
    {
      names: ["heavy_multiplication_x"],
      surrogates: "✖",
    },
    {
      names: ["heavy_dollar_sign"],
      surrogates: "💲",
    },
    {
      names: ["currency_exchange"],
      surrogates: "💱",
    },
    {
      names: ["copyright"],
      surrogates: "©",
    },
    {
      names: ["registered"],
      surrogates: "®",
    },
    {
      names: ["tm"],
      surrogates: "™",
    },
    {
      names: ["end"],
      surrogates: "🔚",
    },
    {
      names: ["back"],
      surrogates: "🔙",
    },
    {
      names: ["on"],
      surrogates: "🔛",
    },
    {
      names: ["top"],
      surrogates: "🔝",
    },
    {
      names: ["soon"],
      surrogates: "🔜",
    },
    {
      names: ["ballot_box_with_check"],
      surrogates: "☑",
    },
    {
      names: ["radio_button"],
      surrogates: "🔘",
    },
    {
      names: ["white_circle"],
      surrogates: "⚪",
    },
    {
      names: ["black_circle"],
      surrogates: "⚫",
    },
    {
      names: ["red_circle"],
      surrogates: "🔴",
    },
    {
      names: ["large_blue_circle"],
      surrogates: "🔵",
    },
    {
      names: ["small_orange_diamond"],
      surrogates: "🔸",
    },
    {
      names: ["small_blue_diamond"],
      surrogates: "🔹",
    },
    {
      names: ["large_orange_diamond"],
      surrogates: "🔶",
    },
    {
      names: ["large_blue_diamond"],
      surrogates: "🔷",
    },
    {
      names: ["small_red_triangle"],
      surrogates: "🔺",
    },
    {
      names: ["black_small_square"],
      surrogates: "▪",
    },
    {
      names: ["white_small_square"],
      surrogates: "▫",
    },
    {
      names: ["black_large_square"],
      surrogates: "⬛",
    },
    {
      names: ["white_large_square"],
      surrogates: "⬜",
    },
    {
      names: ["small_red_triangle_down"],
      surrogates: "🔻",
    },
    {
      names: ["black_medium_square"],
      surrogates: "◼",
    },
    {
      names: ["white_medium_square"],
      surrogates: "◻",
    },
    {
      names: ["black_medium_small_square"],
      surrogates: "◾",
    },
    {
      names: ["white_medium_small_square"],
      surrogates: "◽",
    },
    {
      names: ["black_square_button"],
      surrogates: "🔲",
    },
    {
      names: ["white_square_button"],
      surrogates: "🔳",
    },
    {
      names: ["speaker"],
      surrogates: "🔈",
    },
    {
      names: ["sound"],
      surrogates: "🔉",
    },
    {
      names: ["loud_sound"],
      surrogates: "🔊",
    },
    {
      names: ["mute"],
      surrogates: "🔇",
    },
    {
      names: ["mega"],
      surrogates: "📣",
    },
    {
      names: ["loudspeaker"],
      surrogates: "📢",
    },
    {
      names: ["bell"],
      surrogates: "🔔",
    },
    {
      names: ["no_bell"],
      surrogates: "🔕",
    },
    {
      names: ["black_joker"],
      surrogates: "🃏",
    },
    {
      names: ["mahjong"],
      surrogates: "🀄",
    },
    {
      names: ["spades"],
      surrogates: "♠",
    },
    {
      names: ["clubs"],
      surrogates: "♣",
    },
    {
      names: ["hearts"],
      surrogates: "♥",
    },
    {
      names: ["diamonds"],
      surrogates: "♦",
    },
    {
      names: ["flower_playing_cards"],
      surrogates: "🎴",
    },
    {
      names: ["thought_balloon"],
      surrogates: "💭",
    },
    {
      names: ["anger_right", "right_anger_bubble"],
      surrogates: "🗯",
    },
    {
      names: ["speech_balloon"],
      surrogates: "💬",
    },
    {
      names: ["clock1"],
      surrogates: "🕐",
    },
    {
      names: ["clock2"],
      surrogates: "🕑",
    },
    {
      names: ["clock3"],
      surrogates: "🕒",
    },
    {
      names: ["clock4"],
      surrogates: "🕓",
    },
    {
      names: ["clock5"],
      surrogates: "🕔",
    },
    {
      names: ["clock6"],
      surrogates: "🕕",
    },
    {
      names: ["clock7"],
      surrogates: "🕖",
    },
    {
      names: ["clock8"],
      surrogates: "🕗",
    },
    {
      names: ["clock9"],
      surrogates: "🕘",
    },
    {
      names: ["clock10"],
      surrogates: "🕙",
    },
    {
      names: ["clock11"],
      surrogates: "🕚",
    },
    {
      names: ["clock12"],
      surrogates: "🕛",
    },
    {
      names: ["clock130"],
      surrogates: "🕜",
    },
    {
      names: ["clock230"],
      surrogates: "🕝",
    },
    {
      names: ["clock330"],
      surrogates: "🕞",
    },
    {
      names: ["clock430"],
      surrogates: "🕟",
    },
    {
      names: ["clock530"],
      surrogates: "🕠",
    },
    {
      names: ["clock630"],
      surrogates: "🕡",
    },
    {
      names: ["clock730"],
      surrogates: "🕢",
    },
    {
      names: ["clock830"],
      surrogates: "🕣",
    },
    {
      names: ["clock930"],
      surrogates: "🕤",
    },
    {
      names: ["clock1030"],
      surrogates: "🕥",
    },
    {
      names: ["clock1130"],
      surrogates: "🕦",
    },
    {
      names: ["clock1230"],
      surrogates: "🕧",
    },
    {
      names: ["eye_in_speech_bubble"],
      surrogates: "👁‍🗨",
    },
    {
      names: ["speech_left", "left_speech_bubble"],
      surrogates: "🗨",
    },
    {
      names: ["eject", "eject_symbol"],
      surrogates: "⏏",
    },
    {
      names: ["black_heart"],
      surrogates: "🖤",
    },
    {
      names: ["octagonal_sign", "stop_sign"],
      surrogates: "🛑",
    },
    {
      names: ["regional_indicator_z"],
      surrogates: "🇿",
    },
    {
      names: ["regional_indicator_y"],
      surrogates: "🇾",
    },
    {
      names: ["regional_indicator_x"],
      surrogates: "🇽",
    },
    {
      names: ["regional_indicator_w"],
      surrogates: "🇼",
    },
    {
      names: ["regional_indicator_v"],
      surrogates: "🇻",
    },
    {
      names: ["regional_indicator_u"],
      surrogates: "🇺",
    },
    {
      names: ["regional_indicator_t"],
      surrogates: "🇹",
    },
    {
      names: ["regional_indicator_s"],
      surrogates: "🇸",
    },
    {
      names: ["regional_indicator_r"],
      surrogates: "🇷",
    },
    {
      names: ["regional_indicator_q"],
      surrogates: "🇶",
    },
    {
      names: ["regional_indicator_p"],
      surrogates: "🇵",
    },
    {
      names: ["regional_indicator_o"],
      surrogates: "🇴",
    },
    {
      names: ["regional_indicator_n"],
      surrogates: "🇳",
    },
    {
      names: ["regional_indicator_m"],
      surrogates: "🇲",
    },
    {
      names: ["regional_indicator_l"],
      surrogates: "🇱",
    },
    {
      names: ["regional_indicator_k"],
      surrogates: "🇰",
    },
    {
      names: ["regional_indicator_j"],
      surrogates: "🇯",
    },
    {
      names: ["regional_indicator_i"],
      surrogates: "🇮",
    },
    {
      names: ["regional_indicator_h"],
      surrogates: "🇭",
    },
    {
      names: ["regional_indicator_g"],
      surrogates: "🇬",
    },
    {
      names: ["regional_indicator_f"],
      surrogates: "🇫",
    },
    {
      names: ["regional_indicator_e"],
      surrogates: "🇪",
    },
    {
      names: ["regional_indicator_d"],
      surrogates: "🇩",
    },
    {
      names: ["regional_indicator_c"],
      surrogates: "🇨",
    },
    {
      names: ["regional_indicator_b"],
      surrogates: "🇧",
    },
    {
      names: ["regional_indicator_a"],
      surrogates: "🇦",
    },
  ],
  flags: [
    {
      names: ["flag_ac"],
      surrogates: "🇦🇨",
    },
    {
      names: ["flag_af"],
      surrogates: "🇦🇫",
    },
    {
      names: ["flag_al"],
      surrogates: "🇦🇱",
    },
    {
      names: ["flag_dz"],
      surrogates: "🇩🇿",
    },
    {
      names: ["flag_ad"],
      surrogates: "🇦🇩",
    },
    {
      names: ["flag_ao"],
      surrogates: "🇦🇴",
    },
    {
      names: ["flag_ai"],
      surrogates: "🇦🇮",
    },
    {
      names: ["flag_ag"],
      surrogates: "🇦🇬",
    },
    {
      names: ["flag_ar"],
      surrogates: "🇦🇷",
    },
    {
      names: ["flag_am"],
      surrogates: "🇦🇲",
    },
    {
      names: ["flag_aw"],
      surrogates: "🇦🇼",
    },
    {
      names: ["flag_au"],
      surrogates: "🇦🇺",
    },
    {
      names: ["flag_at"],
      surrogates: "🇦🇹",
    },
    {
      names: ["flag_az"],
      surrogates: "🇦🇿",
    },
    {
      names: ["flag_bs"],
      surrogates: "🇧🇸",
    },
    {
      names: ["flag_bh"],
      surrogates: "🇧🇭",
    },
    {
      names: ["flag_bd"],
      surrogates: "🇧🇩",
    },
    {
      names: ["flag_bb"],
      surrogates: "🇧🇧",
    },
    {
      names: ["flag_by"],
      surrogates: "🇧🇾",
    },
    {
      names: ["flag_be"],
      surrogates: "🇧🇪",
    },
    {
      names: ["flag_bz"],
      surrogates: "🇧🇿",
    },
    {
      names: ["flag_bj"],
      surrogates: "🇧🇯",
    },
    {
      names: ["flag_bm"],
      surrogates: "🇧🇲",
    },
    {
      names: ["flag_bt"],
      surrogates: "🇧🇹",
    },
    {
      names: ["flag_bo"],
      surrogates: "🇧🇴",
    },
    {
      names: ["flag_ba"],
      surrogates: "🇧🇦",
    },
    {
      names: ["flag_bw"],
      surrogates: "🇧🇼",
    },
    {
      names: ["flag_br"],
      surrogates: "🇧🇷",
    },
    {
      names: ["flag_bn"],
      surrogates: "🇧🇳",
    },
    {
      names: ["flag_bg"],
      surrogates: "🇧🇬",
    },
    {
      names: ["flag_bf"],
      surrogates: "🇧🇫",
    },
    {
      names: ["flag_bi"],
      surrogates: "🇧🇮",
    },
    {
      names: ["flag_cv"],
      surrogates: "🇨🇻",
    },
    {
      names: ["flag_kh"],
      surrogates: "🇰🇭",
    },
    {
      names: ["flag_cm"],
      surrogates: "🇨🇲",
    },
    {
      names: ["flag_ca"],
      surrogates: "🇨🇦",
    },
    {
      names: ["flag_ky"],
      surrogates: "🇰🇾",
    },
    {
      names: ["flag_cf"],
      surrogates: "🇨🇫",
    },
    {
      names: ["flag_td"],
      surrogates: "🇹🇩",
    },
    {
      names: ["flag_cl"],
      surrogates: "🇨🇱",
    },
    {
      names: ["flag_cn"],
      surrogates: "🇨🇳",
    },
    {
      names: ["flag_co"],
      surrogates: "🇨🇴",
    },
    {
      names: ["flag_km"],
      surrogates: "🇰🇲",
    },
    {
      names: ["flag_cg"],
      surrogates: "🇨🇬",
    },
    {
      names: ["flag_cd"],
      surrogates: "🇨🇩",
    },
    {
      names: ["flag_cr"],
      surrogates: "🇨🇷",
    },
    {
      names: ["flag_hr"],
      surrogates: "🇭🇷",
    },
    {
      names: ["flag_cu"],
      surrogates: "🇨🇺",
    },
    {
      names: ["flag_cy"],
      surrogates: "🇨🇾",
    },
    {
      names: ["flag_cz"],
      surrogates: "🇨🇿",
    },
    {
      names: ["flag_dk"],
      surrogates: "🇩🇰",
    },
    {
      names: ["flag_dj"],
      surrogates: "🇩🇯",
    },
    {
      names: ["flag_dm"],
      surrogates: "🇩🇲",
    },
    {
      names: ["flag_do"],
      surrogates: "🇩🇴",
    },
    {
      names: ["flag_ec"],
      surrogates: "🇪🇨",
    },
    {
      names: ["flag_eg"],
      surrogates: "🇪🇬",
    },
    {
      names: ["flag_sv"],
      surrogates: "🇸🇻",
    },
    {
      names: ["flag_gq"],
      surrogates: "🇬🇶",
    },
    {
      names: ["flag_er"],
      surrogates: "🇪🇷",
    },
    {
      names: ["flag_ee"],
      surrogates: "🇪🇪",
    },
    {
      names: ["flag_et"],
      surrogates: "🇪🇹",
    },
    {
      names: ["flag_fk"],
      surrogates: "🇫🇰",
    },
    {
      names: ["flag_fo"],
      surrogates: "🇫🇴",
    },
    {
      names: ["flag_fj"],
      surrogates: "🇫🇯",
    },
    {
      names: ["flag_fi"],
      surrogates: "🇫🇮",
    },
    {
      names: ["flag_fr"],
      surrogates: "🇫🇷",
    },
    {
      names: ["flag_pf"],
      surrogates: "🇵🇫",
    },
    {
      names: ["flag_ga"],
      surrogates: "🇬🇦",
    },
    {
      names: ["flag_gm"],
      surrogates: "🇬🇲",
    },
    {
      names: ["flag_ge"],
      surrogates: "🇬🇪",
    },
    {
      names: ["flag_de"],
      surrogates: "🇩🇪",
    },
    {
      names: ["flag_gh"],
      surrogates: "🇬🇭",
    },
    {
      names: ["flag_gi"],
      surrogates: "🇬🇮",
    },
    {
      names: ["flag_gr"],
      surrogates: "🇬🇷",
    },
    {
      names: ["flag_gl"],
      surrogates: "🇬🇱",
    },
    {
      names: ["flag_gd"],
      surrogates: "🇬🇩",
    },
    {
      names: ["flag_gu"],
      surrogates: "🇬🇺",
    },
    {
      names: ["flag_gt"],
      surrogates: "🇬🇹",
    },
    {
      names: ["flag_gn"],
      surrogates: "🇬🇳",
    },
    {
      names: ["flag_gw"],
      surrogates: "🇬🇼",
    },
    {
      names: ["flag_gy"],
      surrogates: "🇬🇾",
    },
    {
      names: ["flag_ht"],
      surrogates: "🇭🇹",
    },
    {
      names: ["flag_hn"],
      surrogates: "🇭🇳",
    },
    {
      names: ["flag_hk"],
      surrogates: "🇭🇰",
    },
    {
      names: ["flag_hu"],
      surrogates: "🇭🇺",
    },
    {
      names: ["flag_is"],
      surrogates: "🇮🇸",
    },
    {
      names: ["flag_in"],
      surrogates: "🇮🇳",
    },
    {
      names: ["flag_id"],
      surrogates: "🇮🇩",
    },
    {
      names: ["flag_ir"],
      surrogates: "🇮🇷",
    },
    {
      names: ["flag_iq"],
      surrogates: "🇮🇶",
    },
    {
      names: ["flag_ie"],
      surrogates: "🇮🇪",
    },
    {
      names: ["flag_il"],
      surrogates: "🇮🇱",
    },
    {
      names: ["flag_it"],
      surrogates: "🇮🇹",
    },
    {
      names: ["flag_ci"],
      surrogates: "🇨🇮",
    },
    {
      names: ["flag_jm"],
      surrogates: "🇯🇲",
    },
    {
      names: ["flag_jp"],
      surrogates: "🇯🇵",
    },
    {
      names: ["flag_je"],
      surrogates: "🇯🇪",
    },
    {
      names: ["flag_jo"],
      surrogates: "🇯🇴",
    },
    {
      names: ["flag_kz"],
      surrogates: "🇰🇿",
    },
    {
      names: ["flag_ke"],
      surrogates: "🇰🇪",
    },
    {
      names: ["flag_ki"],
      surrogates: "🇰🇮",
    },
    {
      names: ["flag_xk"],
      surrogates: "🇽🇰",
    },
    {
      names: ["flag_kw"],
      surrogates: "🇰🇼",
    },
    {
      names: ["flag_kg"],
      surrogates: "🇰🇬",
    },
    {
      names: ["flag_la"],
      surrogates: "🇱🇦",
    },
    {
      names: ["flag_lv"],
      surrogates: "🇱🇻",
    },
    {
      names: ["flag_lb"],
      surrogates: "🇱🇧",
    },
    {
      names: ["flag_ls"],
      surrogates: "🇱🇸",
    },
    {
      names: ["flag_lr"],
      surrogates: "🇱🇷",
    },
    {
      names: ["flag_ly"],
      surrogates: "🇱🇾",
    },
    {
      names: ["flag_li"],
      surrogates: "🇱🇮",
    },
    {
      names: ["flag_lt"],
      surrogates: "🇱🇹",
    },
    {
      names: ["flag_lu"],
      surrogates: "🇱🇺",
    },
    {
      names: ["flag_mo"],
      surrogates: "🇲🇴",
    },
    {
      names: ["flag_mk"],
      surrogates: "🇲🇰",
    },
    {
      names: ["flag_mg"],
      surrogates: "🇲🇬",
    },
    {
      names: ["flag_mw"],
      surrogates: "🇲🇼",
    },
    {
      names: ["flag_my"],
      surrogates: "🇲🇾",
    },
    {
      names: ["flag_mv"],
      surrogates: "🇲🇻",
    },
    {
      names: ["flag_ml"],
      surrogates: "🇲🇱",
    },
    {
      names: ["flag_mt"],
      surrogates: "🇲🇹",
    },
    {
      names: ["flag_mh"],
      surrogates: "🇲🇭",
    },
    {
      names: ["flag_mr"],
      surrogates: "🇲🇷",
    },
    {
      names: ["flag_mu"],
      surrogates: "🇲🇺",
    },
    {
      names: ["flag_mx"],
      surrogates: "🇲🇽",
    },
    {
      names: ["flag_fm"],
      surrogates: "🇫🇲",
    },
    {
      names: ["flag_md"],
      surrogates: "🇲🇩",
    },
    {
      names: ["flag_mc"],
      surrogates: "🇲🇨",
    },
    {
      names: ["flag_mn"],
      surrogates: "🇲🇳",
    },
    {
      names: ["flag_me"],
      surrogates: "🇲🇪",
    },
    {
      names: ["flag_ms"],
      surrogates: "🇲🇸",
    },
    {
      names: ["flag_ma"],
      surrogates: "🇲🇦",
    },
    {
      names: ["flag_mz"],
      surrogates: "🇲🇿",
    },
    {
      names: ["flag_mm"],
      surrogates: "🇲🇲",
    },
    {
      names: ["flag_na"],
      surrogates: "🇳🇦",
    },
    {
      names: ["flag_nr"],
      surrogates: "🇳🇷",
    },
    {
      names: ["flag_np"],
      surrogates: "🇳🇵",
    },
    {
      names: ["flag_nl"],
      surrogates: "🇳🇱",
    },
    {
      names: ["flag_nc"],
      surrogates: "🇳🇨",
    },
    {
      names: ["flag_nz"],
      surrogates: "🇳🇿",
    },
    {
      names: ["flag_ni"],
      surrogates: "🇳🇮",
    },
    {
      names: ["flag_ne"],
      surrogates: "🇳🇪",
    },
    {
      names: ["flag_ng"],
      surrogates: "🇳🇬",
    },
    {
      names: ["flag_nu"],
      surrogates: "🇳🇺",
    },
    {
      names: ["flag_kp"],
      surrogates: "🇰🇵",
    },
    {
      names: ["flag_no"],
      surrogates: "🇳🇴",
    },
    {
      names: ["flag_om"],
      surrogates: "🇴🇲",
    },
    {
      names: ["flag_pk"],
      surrogates: "🇵🇰",
    },
    {
      names: ["flag_pw"],
      surrogates: "🇵🇼",
    },
    {
      names: ["flag_ps"],
      surrogates: "🇵🇸",
    },
    {
      names: ["flag_pa"],
      surrogates: "🇵🇦",
    },
    {
      names: ["flag_pg"],
      surrogates: "🇵🇬",
    },
    {
      names: ["flag_py"],
      surrogates: "🇵🇾",
    },
    {
      names: ["flag_pe"],
      surrogates: "🇵🇪",
    },
    {
      names: ["flag_ph"],
      surrogates: "🇵🇭",
    },
    {
      names: ["flag_pl"],
      surrogates: "🇵🇱",
    },
    {
      names: ["flag_pt"],
      surrogates: "🇵🇹",
    },
    {
      names: ["flag_pr"],
      surrogates: "🇵🇷",
    },
    {
      names: ["flag_qa"],
      surrogates: "🇶🇦",
    },
    {
      names: ["flag_ro"],
      surrogates: "🇷🇴",
    },
    {
      names: ["flag_ru"],
      surrogates: "🇷🇺",
    },
    {
      names: ["flag_rw"],
      surrogates: "🇷🇼",
    },
    {
      names: ["flag_sh"],
      surrogates: "🇸🇭",
    },
    {
      names: ["flag_kn"],
      surrogates: "🇰🇳",
    },
    {
      names: ["flag_lc"],
      surrogates: "🇱🇨",
    },
    {
      names: ["flag_vc"],
      surrogates: "🇻🇨",
    },
    {
      names: ["flag_ws"],
      surrogates: "🇼🇸",
    },
    {
      names: ["flag_sm"],
      surrogates: "🇸🇲",
    },
    {
      names: ["flag_st"],
      surrogates: "🇸🇹",
    },
    {
      names: ["flag_sa"],
      surrogates: "🇸🇦",
    },
    {
      names: ["flag_sn"],
      surrogates: "🇸🇳",
    },
    {
      names: ["flag_rs"],
      surrogates: "🇷🇸",
    },
    {
      names: ["flag_sc"],
      surrogates: "🇸🇨",
    },
    {
      names: ["flag_sl"],
      surrogates: "🇸🇱",
    },
    {
      names: ["flag_sg"],
      surrogates: "🇸🇬",
    },
    {
      names: ["flag_sk"],
      surrogates: "🇸🇰",
    },
    {
      names: ["flag_si"],
      surrogates: "🇸🇮",
    },
    {
      names: ["flag_sb"],
      surrogates: "🇸🇧",
    },
    {
      names: ["flag_so"],
      surrogates: "🇸🇴",
    },
    {
      names: ["flag_za"],
      surrogates: "🇿🇦",
    },
    {
      names: ["flag_kr"],
      surrogates: "🇰🇷",
    },
    {
      names: ["flag_es"],
      surrogates: "🇪🇸",
    },
    {
      names: ["flag_lk"],
      surrogates: "🇱🇰",
    },
    {
      names: ["flag_sd"],
      surrogates: "🇸🇩",
    },
    {
      names: ["flag_sr"],
      surrogates: "🇸🇷",
    },
    {
      names: ["flag_sz"],
      surrogates: "🇸🇿",
    },
    {
      names: ["flag_se"],
      surrogates: "🇸🇪",
    },
    {
      names: ["flag_ch"],
      surrogates: "🇨🇭",
    },
    {
      names: ["flag_sy"],
      surrogates: "🇸🇾",
    },
    {
      names: ["flag_tw"],
      surrogates: "🇹🇼",
    },
    {
      names: ["flag_tj"],
      surrogates: "🇹🇯",
    },
    {
      names: ["flag_tz"],
      surrogates: "🇹🇿",
    },
    {
      names: ["flag_th"],
      surrogates: "🇹🇭",
    },
    {
      names: ["flag_tl"],
      surrogates: "🇹🇱",
    },
    {
      names: ["flag_tg"],
      surrogates: "🇹🇬",
    },
    {
      names: ["flag_to"],
      surrogates: "🇹🇴",
    },
    {
      names: ["flag_tt"],
      surrogates: "🇹🇹",
    },
    {
      names: ["flag_tn"],
      surrogates: "🇹🇳",
    },
    {
      names: ["flag_tr"],
      surrogates: "🇹🇷",
    },
    {
      names: ["flag_tm"],
      surrogates: "🇹🇲",
    },
    {
      names: ["flag_tv"],
      surrogates: "🇹🇻",
    },
    {
      names: ["flag_ug"],
      surrogates: "🇺🇬",
    },
    {
      names: ["flag_ua"],
      surrogates: "🇺🇦",
    },
    {
      names: ["flag_ae"],
      surrogates: "🇦🇪",
    },
    {
      names: ["flag_gb"],
      surrogates: "🇬🇧",
    },
    {
      names: ["flag_us"],
      surrogates: "🇺🇸",
    },
    {
      names: ["flag_vi"],
      surrogates: "🇻🇮",
    },
    {
      names: ["flag_uy"],
      surrogates: "🇺🇾",
    },
    {
      names: ["flag_uz"],
      surrogates: "🇺🇿",
    },
    {
      names: ["flag_vu"],
      surrogates: "🇻🇺",
    },
    {
      names: ["flag_va"],
      surrogates: "🇻🇦",
    },
    {
      names: ["flag_ve"],
      surrogates: "🇻🇪",
    },
    {
      names: ["flag_vn"],
      surrogates: "🇻🇳",
    },
    {
      names: ["flag_wf"],
      surrogates: "🇼🇫",
    },
    {
      names: ["flag_eh"],
      surrogates: "🇪🇭",
    },
    {
      names: ["flag_ye"],
      surrogates: "🇾🇪",
    },
    {
      names: ["flag_zm"],
      surrogates: "🇿🇲",
    },
    {
      names: ["flag_zw"],
      surrogates: "🇿🇼",
    },
    {
      names: ["flag_re"],
      surrogates: "🇷🇪",
    },
    {
      names: ["flag_ax"],
      surrogates: "🇦🇽",
    },
    {
      names: ["flag_ta"],
      surrogates: "🇹🇦",
    },
    {
      names: ["flag_io"],
      surrogates: "🇮🇴",
    },
    {
      names: ["flag_bq"],
      surrogates: "🇧🇶",
    },
    {
      names: ["flag_cx"],
      surrogates: "🇨🇽",
    },
    {
      names: ["flag_cc"],
      surrogates: "🇨🇨",
    },
    {
      names: ["flag_gg"],
      surrogates: "🇬🇬",
    },
    {
      names: ["flag_im"],
      surrogates: "🇮🇲",
    },
    {
      names: ["flag_yt"],
      surrogates: "🇾🇹",
    },
    {
      names: ["flag_nf"],
      surrogates: "🇳🇫",
    },
    {
      names: ["flag_pn"],
      surrogates: "🇵🇳",
    },
    {
      names: ["flag_bl"],
      surrogates: "🇧🇱",
    },
    {
      names: ["flag_pm"],
      surrogates: "🇵🇲",
    },
    {
      names: ["flag_gs"],
      surrogates: "🇬🇸",
    },
    {
      names: ["flag_tk"],
      surrogates: "🇹🇰",
    },
    {
      names: ["flag_bv"],
      surrogates: "🇧🇻",
    },
    {
      names: ["flag_hm"],
      surrogates: "🇭🇲",
    },
    {
      names: ["flag_sj"],
      surrogates: "🇸🇯",
    },
    {
      names: ["flag_um"],
      surrogates: "🇺🇲",
    },
    {
      names: ["flag_ic"],
      surrogates: "🇮🇨",
    },
    {
      names: ["flag_ea"],
      surrogates: "🇪🇦",
    },
    {
      names: ["flag_cp"],
      surrogates: "🇨🇵",
    },
    {
      names: ["flag_dg"],
      surrogates: "🇩🇬",
    },
    {
      names: ["flag_as"],
      surrogates: "🇦🇸",
    },
    {
      names: ["flag_aq"],
      surrogates: "🇦🇶",
    },
    {
      names: ["flag_vg"],
      surrogates: "🇻🇬",
    },
    {
      names: ["flag_ck"],
      surrogates: "🇨🇰",
    },
    {
      names: ["flag_cw"],
      surrogates: "🇨🇼",
    },
    {
      names: ["flag_eu"],
      surrogates: "🇪🇺",
    },
    {
      names: ["flag_gf"],
      surrogates: "🇬🇫",
    },
    {
      names: ["flag_tf"],
      surrogates: "🇹🇫",
    },
    {
      names: ["flag_gp"],
      surrogates: "🇬🇵",
    },
    {
      names: ["flag_mq"],
      surrogates: "🇲🇶",
    },
    {
      names: ["flag_mp"],
      surrogates: "🇲🇵",
    },
    {
      names: ["flag_sx"],
      surrogates: "🇸🇽",
    },
    {
      names: ["flag_ss"],
      surrogates: "🇸🇸",
    },
    {
      names: ["flag_tc"],
      surrogates: "🇹🇨",
    },
    {
      names: ["flag_mf"],
      surrogates: "🇲🇫",
    },
    {
      names: ["gay_pride_flag", "rainbow_flag"],
      surrogates: "🏳️‍🌈",
    },
  ],
} as const;
